import { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighChartsSankey from 'highcharts/modules/sankey';

import { useLocation, useSearchParams, NavLink, useNavigate } from "react-router-dom";
import { getLastDateOfMonth, getGoogleAnalyticsId, getPastDate, getNumberOfDaysBetweenDates, putCommasInNumber } from "../../utils/utils";
import { DownloadButton } from "../common/download_button";
import Select from 'react-select';

import { observer } from "mobx-react";
import { metadataStore } from "../../stores/metadata_store";
import { dataVersionDateStore } from "../../stores/data_version_date_store";
import LoadingWithText from "../common/loading_text";
import { toJS } from "mobx";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import { collectionsStore } from '../../stores/collections_store';
import Dropdown from '../common/collections_dropdown';
import { talentInflowAndOutflowDataStore } from "../../stores/talent_inflow_outflow_data_store";
import { mixpanelActions } from "../../utils/mixpanel_util";
import { amplitudeActions } from "../../utils/amplitude_util";


HighChartsSankey(Highcharts);

const CompanyTalentFlowComponent = observer(() => {
    const [talentFlowData, setTalentFlowData] = useState(null);
    const [totalInflow, setTotalInflow] = useState(0);
    const [totalOutflow, setTotalOutflow] = useState(0);
    const [privateInflow, setPrivateInflow] = useState(0);
    const [pendingOutflow, setPendingOutflow] = useState(0);
    const [totalFemaleInflowProb, setTotalFemaleInflowProb] = useState(0);
    const [totalFemaleOutflowProb, setTotalFemaleOutflowProb] = useState(0);
    const [companyName, setCompanyName] = useState('');

    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [showGenderProb, setShowGenderProb] = useState(false);
    const [publicProfileCount, setPublicProfileCount] = useState(0);
    
    const geo1Values = ['North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific'];
    const deptValues = ['Sales', 'Product', 'Administrative', 'Consulting', 'Customer Success & Support', 'Engineering', 'Finance', 'Human Resources', 'Information Technology', 'Marketing', 'Operations', 'Other' ];
    const seniorityValues = ["Individual Contributor", "Advisor / Board", "Executive", "Leadership", "Management", "Student / Intern", "Unsure"];

    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [selectedSeniorities, setSelectedSeniorities] = useState([]);
    const [startMonthTimeOptions, setStartMonthTimeOptions] = useState([]);
    const [endMonthTimeOptions, setEndMonthTimeOptions] = useState([]);
    const [startMonth, setStartMonth] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [startYearTimeOptions, setStartYearTimeOptions] = useState([]);
    const [endYearTimeOptions, setEndYearTimeOptions] = useState([]);
    const [startYear, setStartYear] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [dataVersionDate, setDataVersionDate] = useState(null);
    const [collections, setCollections] = useState([]);

    const [isPageLoading, setIsPageLoading] = useState(false);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    const dataset = searchParams && searchParams.get('dataset') || location.state && location.state.dataset;

    // let dataVersionDate = location.state && location.state.dataVersionDate;

    let companyGrowthPath = `/company/growth?company_id=${companyId}`;
    let teamGrowthPath = `/company/team/growth?company_id=${companyId}`;
    let talentFlowPath = `/company/talent?company_id=${companyId}`;
    let talentRetentionPath = `/company/talent/retention?company_id=${companyId}`
    let talentRetentionPlusPath = `/company/talent/retention/plus?company_id=${companyId}`
    let tenurePath = `/company/tenure?company_id=${companyId}`;
    let topTitlesPath = `/company/top-titles?company_id=${companyId}`;
    let geoPath = `/company/geo?company_id=${companyId}`;
    
    if (accessToken !== null && accessToken !== undefined) {
        companyGrowthPath += `&access_token=${accessToken}`;
        teamGrowthPath += `&access_token=${accessToken}`;
        talentFlowPath += `&access_token=${accessToken}`;
        talentRetentionPath += `&access_token=${accessToken}`;
        talentRetentionPlusPath += `&access_token=${accessToken}`;
        tenurePath += `&access_token=${accessToken}`;
        topTitlesPath += `&access_token=${accessToken}`;
        geoPath += `&access_token=${accessToken}`;
    }

    if (dataset !== null && dataset !== undefined) {
        companyGrowthPath += `&dataset=${dataset}`;
        teamGrowthPath += `&dataset=${dataset}`;
        talentFlowPath += `&dataset=${dataset}`;
        talentRetentionPath += `&dataset=${dataset}`;
        talentRetentionPlusPath += `&dataset=${dataset}`;
        tenurePath += `&dataset=${dataset}`;
        topTitlesPath += `&dataset=${dataset}`;
        geoPath += `&dataset=${dataset}`;
    }
    
    let universeSummaryPath = '';

    if (universeId !== null && universeId !== undefined) {
        companyGrowthPath += `&universe_id=${universeId}`;
        teamGrowthPath += `&universe_id=${universeId}`;
        talentFlowPath += `&universe_id=${universeId}`;
        talentRetentionPath += `&universe_id=${universeId}`;
        talentRetentionPlusPath += `&universe_id=${universeId}`;
        tenurePath += `&universe_id=${universeId}`;
        topTitlesPath += `&universe_id=${universeId}`;
        geoPath += `&universe_id=${universeId}`;
        universeSummaryPath = `/universe/summary?universe_id=${universeId}`;
    }
    
    function getAllTimeOptions() {
        let options = [
            { id: 1, value: getPastDate('3 months', dataVersionDate), label: '3 months' },
            { id: 2, value: getPastDate('6 months', dataVersionDate), label: '6 months' },
            { id: 3, value: getPastDate('1 year', dataVersionDate), label: '1 year' },
            { id: 4, value: getPastDate('2 years', dataVersionDate), label: '2 years' },
            { id: 5, value: getPastDate('3 years', dataVersionDate), label: '3 years' },
            { id: 6, value: getPastDate('5 years', dataVersionDate), label: '5 years' },
            { id: 7, value: getPastDate('7 years', dataVersionDate), label: '7 years' },
            { id: 8, value: getPastDate('10 years', dataVersionDate), label: '10 years' },
            { id: 9, value: "Custom", label: "Custom"},
        ];
        
        return options;
    }

    // function to get month name from utc month number
    function getMonthName(monthNum) {
        let monthName = '';
        switch (monthNum) {
            case 0:
                monthName = 'Jan';
                break;
            case 1:
                monthName = 'Feb';
                break;
            case 2:
                monthName = 'Mar';
                break;
            case 3:
                monthName = 'Apr';
                break;
            case 4:
                monthName = 'May';
                break;
            case 5:
                monthName = 'Jun';
                break;
            case 6:
                monthName = 'Jul';
                break;
            case 7:
                monthName = 'Aug';
                break;
            case 8:
                monthName = 'Sep';
                break;
            case 9:
                monthName = 'Oct';
                break;
            case 10:
                monthName = 'Nov';
                break;
            case 11:
                monthName = 'Dec';
                break;
            default:
                monthName = '';
        }

        return monthName;
    }
    
    const handleDeptChangeWithMultiSelect = (selectedOptions) => {
        
        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedDepartments(selectedOptions);
        } else {
            setSelectedDepartments([selectedOptions]);
        }

       // update div style to be visible
       let refreshDataDiv = document.getElementById('refresh-data-text');
       refreshDataDiv.style.display = 'block';
    };

    const handleSeniorityChangeWithMultiSelect = (selectedOptions) => {
       
        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedSeniorities(selectedOptions);
        } else {
            setSelectedSeniorities([selectedOptions]);
        }

        // update div style to be visible
        let refreshDataDiv = document.getElementById('refresh-data-text');
        refreshDataDiv.style.display = 'block';
    };

    const handleGeo1ChangeWithMultiSelect = (selectedOptions) => {

        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedRegions(selectedOptions);
        } else {
            setSelectedRegions([selectedOptions]);
        }

        // update div style to be visible
        let refreshDataDiv = document.getElementById('refresh-data-text');
        refreshDataDiv.style.display = 'block';
    };

    const handleStartTimeMonthChange = (selectedOption) => {
        setStartMonth(selectedOption.id);
        // update div style to be visible
        let refreshDataDiv = document.getElementById('refresh-data-text');
        refreshDataDiv.style.display = 'block';
    };

    const handleStartTimeYearChange = (selectedOption) => {
        setStartYear(selectedOption.value);
        // update div style to be visible
        let refreshDataDiv = document.getElementById('refresh-data-text');
        refreshDataDiv.style.display = 'block';
    };

    const handleEndTimeMonthChange = (selectedOption) => {
        setEndMonth(selectedOption.id);
        // update div style to be visible
        let refreshDataDiv = document.getElementById('refresh-data-text');
        refreshDataDiv.style.display = 'block';
    };

    const handleEndTimeYearChange = (selectedOption) => {
        setEndYear(selectedOption.value);
        // update div style to be visible
        let refreshDataDiv = document.getElementById('refresh-data-text');
        refreshDataDiv.style.display = 'block';
    };

    const handleRefreshClicked = () => {
        if (startYear > endYear) {
            alert('Start year cannot be greater than End year');
            return;
        }
        if (startYear === endYear && startMonth > endMonth) {
            alert('Start month cannot be greater than End month');
            return;
        }

        setIsPageLoading(true);
        getTalentFlowData();
    };

    const displayTalentRetention = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com" 
                || emailDomain === "endava.com" 
                || emailDomain === "bcg.com"
                || emailDomain === "telemetryllc.com" 
                || emailDomain === "telemetry-llc.com" 
                || emailDomain === "american-securities.com") 
            {
                return true;
            }
            else if (emailDomain === "gmail.com") {
                if (userAccountDataStore.user.email === 'zoltan.peresztegi@gmail.com') {
                    return true;
                }
            }
        }
        return false;
    };

    const displayTalentRetentionPlus = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com" ) 
            {
                return true;
            }
        }
        return false;
    };

    const getMetaData = async() => {
        let promiseArray = [
            metadataStore.fetchMetadata(companyId, accessToken, dataset),
            dataVersionDateStore.fetchDataVersionDate(accessToken),
        ];
        
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            promiseArray.push(collectionsStore.fetchCollections(userAccountDataStore.user.email, companyId));
        }

        // await all promises in parallel
        await Promise.all(promiseArray);

        setCompanyName(metadataStore.data[companyId].companyName);
        if (metadataStore.data[companyId].publicProfileCount !== undefined) {
            setPublicProfileCount(metadataStore.data[companyId].publicProfileCount);
        }
        setDataVersionDate(toJS(dataVersionDateStore.dataVersionDate));

        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            setCollections(toJS(collectionsStore.collections[companyId]));
        }

        let options = getAllTimeOptions();
        
        let startMonthOptions = [];
        let endMonthOptions = [];
        let startYearOptions = [];
        let endYearOptions = [];

        const endYearValue = new Date(dataVersionDateStore.dataVersionDate).getUTCFullYear();
        // const startYearValue = new Date(options[4].value).getUTCFullYear();
        const startYearValue = endYearValue - 3;

        // set startMonthOptions and endMonthOptions
        for (let i = 0; i < 12; i++) {
            startMonthOptions.push({ id: i, value: getMonthName(i), label: getMonthName(i) });
            if (i <= new Date(dataVersionDateStore.dataVersionDate).getUTCMonth()) {
                endMonthOptions.push({ id: i, value: getMonthName(i), label: getMonthName(i) });
            }
        }

        // set startYearOptions and endYearOptions
        for (let i = endYearValue - 10; i <= endYearValue; i++) {
            startYearOptions.push({ id: i, value: i, label: i });
            endYearOptions.push({ id: i, value: i, label: i });
        }

        // setStartMonthTimeOptions(startMonthOptions);
        // setEndMonthTimeOptions(endMonthOptions);
        setStartYearTimeOptions(startYearOptions);
        setEndYearTimeOptions(endYearOptions);

        setStartMonth(new Date(dataVersionDateStore.dataVersionDate).getUTCMonth());
        setEndMonth(new Date(dataVersionDateStore.dataVersionDate).getUTCMonth());
        setStartYear(startYearValue);
        setEndYear(endYearValue);

        setIsMetaDataLoading(false);
    };
    
    const getTalentFlowData = async() => {
        try {
            const startDate = `${startYear}-${startMonth + 1}-01`;
            const endDate = `${endYear}-${endMonth + 1}-01`;

            const geo1 = selectedRegions.map((item) => item.value);
            const dept = selectedDepartments.map((item) => item.value);
            const seniority = selectedSeniorities.map((item) => item.value);

            await talentInflowAndOutflowDataStore.fetchTalentFlowData(companyId, accessToken, startDate, endDate, geo1, dept, seniority, dataset);
                
            const talentFlowData = toJS(talentInflowAndOutflowDataStore.data[companyId]);
            setTalentFlowData(talentFlowData);

            setIsPageLoading(false);
        }
        catch (error) {
            console.log(error);
        }
    };

    function getSankeyChartOptions () {
        let filteredSankeyInData = talentFlowData.filter((item) => item['dataset'] === 'inflow');
        let filteredSankeyOutData = talentFlowData.filter((item) => item['dataset'] === 'outflow');
        
        let companyEmpInStatsMap = {};
        let totalEmpInCount = 0;
        let privateInflowCount = 0;
        let totalSumFirstNameFemaleProbIn = 0;
        let totalNumGenderProbIn = 0;

        for (let i = 0; i < filteredSankeyInData.length; i++) {
            if (filteredSankeyInData[i]['from_company_name'] === 'Private') {
                privateInflowCount += filteredSankeyInData[i]['employee_count'];
            }
            totalEmpInCount += filteredSankeyInData[i]['employee_count'];
            totalSumFirstNameFemaleProbIn += filteredSankeyInData[i]['sum_first_name_female_prob'];
            totalNumGenderProbIn += filteredSankeyInData[i]['num_gender_prob'];

            // skip if from_company_name is Other
            if (filteredSankeyInData[i]['from_company_name'] === 'Other') {
                continue;
            }
            
            if (filteredSankeyInData[i]['from_company_id'] in companyEmpInStatsMap) {
                companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['empCount'] += filteredSankeyInData[i]['employee_count'];
                companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['totalSumFirstNameFemaleProb'] += filteredSankeyInData[i]['sum_first_name_female_prob'];
                companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['totalNumGenderProb'] += filteredSankeyInData[i]['num_gender_prob']
            } else {
                companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']] = {
                    pastCompanyName: filteredSankeyInData[i]['from_company_name'],
                    empCount: filteredSankeyInData[i]['employee_count'],
                    totalSumFirstNameFemaleProb: filteredSankeyInData[i]['sum_first_name_female_prob'],
                    totalNumGenderProb: filteredSankeyInData[i]['num_gender_prob']
                };
            };
        };
        
        const companyEmpInStatsMapSortedByCount = Object.entries(companyEmpInStatsMap).sort((a, b) => b[1]['empCount'] - a[1]['empCount']);
        
        let companyEmpOutStatsMap = {};
        let totalEmpOutCount = 0;
        let pendingOutflowCount = 0;
        let totalSumFirstNameFemaleProbOut = 0;
        let totalNumGenderProbOut = 0;

        for (let i = 0; i < filteredSankeyOutData.length; i++) {
            if (filteredSankeyOutData[i]['to_company_name'] === 'Pending') {
                pendingOutflowCount += filteredSankeyOutData[i]['employee_count'];
            }
            totalEmpOutCount += filteredSankeyOutData[i]['employee_count'];
            totalSumFirstNameFemaleProbOut += filteredSankeyOutData[i]['sum_first_name_female_prob'];
            totalNumGenderProbOut += filteredSankeyOutData[i]['num_gender_prob'];

            // skip if to_company_name is Other
            if (filteredSankeyOutData[i]['to_company_name'] === 'Other') {
                continue;
            }
            
            if (filteredSankeyOutData[i]['to_company_id'] in companyEmpOutStatsMap) {
                companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['empCount'] += filteredSankeyOutData[i]['employee_count'];
                companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['totalSumFirstNameFemaleProb'] += filteredSankeyOutData[i]['sum_first_name_female_prob'];
                companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['totalNumGenderProb'] += filteredSankeyOutData[i]['num_gender_prob']
            } else {
                companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']] = {
                    currentCompanyName: filteredSankeyOutData[i]['to_company_name'], 
                    empCount: filteredSankeyOutData[i]['employee_count'],
                    totalSumFirstNameFemaleProb: filteredSankeyOutData[i]['sum_first_name_female_prob'],
                    totalNumGenderProb: filteredSankeyOutData[i]['num_gender_prob']
                };
            };
        };
        

        const companyEmpOutStatsMapSortedByCount = Object.entries(companyEmpOutStatsMap).sort((a, b) => b[1]['empCount'] - a[1]['empCount']);

        setTotalInflow(totalEmpInCount);
        setTotalOutflow(totalEmpOutCount);
        setPrivateInflow(privateInflowCount);
        setPendingOutflow(pendingOutflowCount);
        setTotalFemaleInflowProb(totalSumFirstNameFemaleProbIn / totalNumGenderProbIn);
        setTotalFemaleOutflowProb(totalSumFirstNameFemaleProbOut / totalNumGenderProbOut);
       
        const sankeyInSeriesData = [];
        const sankeyOutSeriesData = [];

        let count = 0;
        for (let i = 0; i < companyEmpInStatsMapSortedByCount.length; i++) {
            if (companyEmpInStatsMapSortedByCount[i][1]['pastCompanyName'] !== 'Private') {
                let genderProbText = '';
                if (showGenderProb && companyEmpInStatsMapSortedByCount[i][1]['totalNumGenderProb'] >= 10) {
                    genderProbText = ' [' + (companyEmpInStatsMapSortedByCount[i][1]['totalSumFirstNameFemaleProb'] / companyEmpInStatsMapSortedByCount[i][1]['totalNumGenderProb'] * 100).toFixed(0) + '%F]';
                }
                sankeyInSeriesData.push({
                    id: count,
                    from: companyEmpInStatsMapSortedByCount[i][1]['empCount'] + ':' + companyEmpInStatsMapSortedByCount[i][1]['pastCompanyName'] + genderProbText,
                    to: companyName,
                    weight: companyEmpInStatsMapSortedByCount[i][1]['empCount'],
                    color: '#999999',
                });
            };
            if (count === 25){
                break;
            }
            count += 1;
        };
        
        for (let i = 0; i < companyEmpOutStatsMapSortedByCount.length; i++) {
            if (companyEmpOutStatsMapSortedByCount[i][1]['currentCompanyName'] !== 'Pending') {
                let genderProbText = '';
                if (showGenderProb && companyEmpOutStatsMapSortedByCount[i][1]['totalNumGenderProb'] >= 10) {
                    genderProbText = '[' + (companyEmpOutStatsMapSortedByCount[i][1]['totalSumFirstNameFemaleProb'] / companyEmpOutStatsMapSortedByCount[i][1]['totalNumGenderProb'] * 100).toFixed(0) + '%F] ';
                }
                sankeyOutSeriesData.push({
                    id: count,
                    from: companyName,
                    to: genderProbText + companyEmpOutStatsMapSortedByCount[i][1]['currentCompanyName'] + ':' + companyEmpOutStatsMapSortedByCount[i][1]['empCount'],
                    weight: companyEmpOutStatsMapSortedByCount[i][1]['empCount'],
                    color: '#999999',
                });
            };
            if (count === 50) {
                break;
            }
            count += 1;
        };

        const sankeyChartSeriesData = sankeyInSeriesData.concat(sankeyOutSeriesData);
        const startDateLabel = `${startMonthTimeOptions[startMonth].label} ${startYear}`
        const endDateLabel = `${endMonthTimeOptions[endMonth].label} ${endYear}`;

        const dateLabel  = startDateLabel + ' - ' + endDateLabel;
        return {
            chart: {
                height: '55%',
                // renderTo: 'talent-flow-chart-container',
            },

			title: {
				text: `${companyName} - Talent Inflow/Outflow (Public Profiles) ${dateLabel}`,
				align: 'center',
			},

            subtitle: {
                text: (sankeyChartSeriesData.length === 0) ? 'No data available for selected filters' : '',
                align: 'center',
                verticalAlign: 'middle',
                style:{
                    fontSize: '20px',
                },
            },
			
			legend: {
				enabled: true,
			},
			
			series: [{
				keys: ['from', 'to', 'weight'],
                data: sankeyChartSeriesData,
                type: 'sankey',
                name: 'Talent Flow',
                // links: links,
                linkOpacity: 0.5,
                // nodes: nodes,
                tooltip: {
                    nodeFormatter: function () {
                        return this.name.split(':')[1];
                    },
                    pointFormatter: function () {
                        if(this.toNode.name === companyName) {
                            // inflow hover
                            let fromName = this.fromNode.name.split(':')[1];
                            let fromNameWithoutGenderProb = fromName.replace(/\s\[\d+%F\]/, '');
                            return fromNameWithoutGenderProb + ' -> ' + this.toNode.name + ': ' + this.weight;
                        } else {
                            // outflow hover
                            let toName = this.toNode.name.split(':')[0];
                            let toNameWithoutGenderProb = toName.replace(/\[\d+%F]\s/, '');
                            return this.fromNode.name + ' -> ' + toNameWithoutGenderProb + ': ' + this.weight;
                        }
                        //return this.fromNode.name + ' -> ' + this.toNode.name + ': ' + this.weight;
                    }
                },
            }],

            plotOptions: {
                sankey: {
                    nodeWidth: 10,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        color: 'black',
                    },
                },
            },

			credits: {
				enabled: false
			},

            xAxis: {
                type: 'datetime'
              },
              yAxis: {
                title: {
                  text: 'Value'
                }
              },
	
		};
    };


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
        if (userAccountDataStore.user !== null || accessToken !== null || accessToken !== undefined) {
            setIsMetaDataLoading(true);
            getMetaData();
        }
    }, []);

    useEffect(() => {
        if (publicProfileCount !== 0) {
            setIsPageLoading(true);
            getTalentFlowData();
        }
    }, [publicProfileCount]);


    useEffect(() => {
        try {
            if (publicProfileCount != 0 
                && document.getElementById('talent-flow-chart-container') !== null  
                && talentFlowData !== null
                )
            {
                const sankeyChartOptions = getSankeyChartOptions();
                Highcharts.chart('talent-flow-chart-container', sankeyChartOptions);
            };
        }
        catch (error) {
            console.log(error);
        }

    }, [talentFlowData, showGenderProb]);

    useEffect(() => {
        setStartMonthTimeOptions(getMonthOptions(startYear));
    }, [startYear])

    useEffect(() => {
        setEndMonthTimeOptions(getMonthOptions(endYear));
    }, [endYear])

    // Update month options based on the selected year
    const getMonthOptions = (selectedYear) => {
        const versionYear = new Date(dataVersionDate).getUTCFullYear();
        let monthOptions = [];
        if (selectedYear == versionYear) {
            const versionMonth = new Date(dataVersionDate).getUTCMonth();
            for (let i = 0; i <= versionMonth; i++) {
                monthOptions.push({ id: i, value: getMonthName(i), label: getMonthName(i) });
            }
        } else {
            for (let i = 0; i < 12; i++) {
                monthOptions.push({ id: i, value: getMonthName(i), label: getMonthName(i) });
            }
        }
        return monthOptions;
    };

    const customStyles = {
        control: (provided) => ({
        ...provided,
        width: 200,
        }),
        menu: (provided) => ({
        ...provided,
        width: 200,
        }),
    };

    function handleTabClick(path) {
        let type = '';
        if (path.includes("team")) {
            type = "Team Growth";
        }
        else if (path.includes("company")) {
            type = "Company Growth";
        }
        else if (path.includes("tenure")) {
            type = "Tenure";
        }
        else if (path.includes("talent/retention")) {
            type = "Talent Retention";
        }


        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Talent Flow",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Talent Flow",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
        }
        navigate(path);
    };

    if (metadataStore.isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if (publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...', 'Setting up app...']} interval={2000} />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={companyGrowthPath}>Company</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={teamGrowthPath}>Teams</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button active">
                            Talent Flow
                        </div>
                        <span style={{ margin: '5px'}}/>
                        {
                            displayTalentRetention() && (
                                <div className="button">
                                    <a href={talentRetentionPath}>Talent Retention</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetention() && (
                                <span style={{ margin: '5px'}}/>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <div className="button">
                                    <a href={talentRetentionPlusPath}> Retention +</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <span style={{ margin: '5px'}} />
                            )
                        }
                        <div className="button">
                            <a href={tenurePath}>Tenure</a>
                        </div> 
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={topTitlesPath}> Top Titles </a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={geoPath}>Geo</a>
                        </div> 
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px', marginRight: '50px'}}>
                        {/* { universeId !== null && (
                            <NavLink 
                                style={{border: 'black 2px solid'}}
                                className='button'
                                to={universeSummaryPath}
                                state={{universeId: universeId, accessToken: accessToken}}
                                target="_blank" rel="noopener noreferrer"
                            >
                                View Universe
                            </NavLink>
                        )} */}
                       { userAccountDataStore.user !== null && <Dropdown collections={collections}/> }
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Talent Flow: {companyName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <LoadingWithText 
                        texts={[
                            'Requesting data...', 
                            'Setting up filters...', 
                            'Creating inflow sankey...', 
                            'Creating outflow sankey...',
                            'Adding final touches...'
                        ]}
                        interval={2500} 
                    />
                ) : (
                <div>
                <div className='checkbox-group-container'> 
                    <div className='checkbox-container'>
                        <label htmlFor='regions'>Region:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="regions"
                            isMulti={true}
                            options={geo1Values.map((value) => ({value: value, label: value}))}
                            value={selectedRegions}
                            onChange={handleGeo1ChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    <div className='checkbox-container'>
                        <label htmlFor='departments'>Team:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="departments"
                            isMulti={true}
                            options={deptValues.map((value) => ({value: value, label: value}))}
                            value={selectedDepartments}
                            onChange={handleDeptChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    <div className='checkbox-container'>
                        <label htmlFor='seniorities'>Seniority:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="seniorities"
                            isMulti={true}
                            options={seniorityValues.map((value) => ({value: value, label: value}))}
                            value={selectedSeniorities}
                            onChange={handleSeniorityChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <br/>
                { talentFlowData !== null 
                ? (
                    <div>
                        {/* <div className="checkbox-group-container">
                            { timeOptions.map((timeOption) => (
                                <div key={timeOption.id} className="checkbox-container" >
                                    <input
                                        type="radio"
                                        id={timeOption.id}
                                        name="timeOption"
                                        checked={selectedCheckbox.label === timeOption.label}
                                        onChange={() => handleCheckboxChange(timeOption)}
                                    />
                                    <label htmlFor={timeOption.id}>{timeOption.label}</label>
                                </div>
                            ))}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button className='button' onClick={handleRefreshClicked}>Refresh Data</button>
                        </div> */}
                        {/* <div className='checkbox-container'>
                            <button className='button' onClick={() => getTalentFlowData()}>Refresh Data</button>
                        </div> */}
                        <br/>
                        <div className='checkbox-group-container'> 
                            <div className='checkbox-container'>
                                <label htmlFor='startTime'>Start Time:</label>
                                &nbsp;&nbsp;
                                <Select
                                    name="startMonth"
                                    options={startMonthTimeOptions}
                                    value={{value: getMonthName(startMonth), label: getMonthName(startMonth)}}
                                    onChange={handleStartTimeMonthChange}
                                    
                                />
                                &nbsp;&nbsp;
                                <Select
                                    name="startYear"
                                    options={startYearTimeOptions}
                                    value={{value: startYear, label: startYear}}
                                    onChange={handleStartTimeYearChange}
                                   
                                />
                            </div>
                            <div className='checkbox-container'>
                                <label htmlFor='endTime'>End Time:</label>
                                &nbsp;&nbsp;
                                <Select
                                    name="endMonth"
                                    options={endMonthTimeOptions}
                                    value={{value: getMonthName(endMonth), label: getMonthName(endMonth)}}
                                    onChange={handleEndTimeMonthChange}
                                    
                                />
                                &nbsp;&nbsp;
                                <Select
                                    name="endYear"
                                    options={endYearTimeOptions}
                                    value={{value: endYear, label: endYear}}
                                    onChange={handleEndTimeYearChange}
                                    
                                />
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button className='button' onClick={handleRefreshClicked}>Refresh Data</button>
                        </div>
                    </div>
                    )
                : (
                    <div>
                        <LoadingWithText texts={['Setting up different filters and time ranges...']} interval={2500} />
                    </div>
                )
                }
                <br/>
                <div id="refresh-data-text" style={{ alignContent: 'cemter', justifyContent: 'center', textAlign: 'center', display: 'none'}}>
                    Please hit Refresh Data to apply the new filters
                </div>
                <br/>
                <div className='bordered-box-talent'>
                    <label>
                        <input type="checkbox" checked={showGenderProb} onChange={() => setShowGenderProb(!showGenderProb)} />
                        &nbsp;Show Gender Probability
                    </label>
                </div>
                <div className='chart-container'>
                    <div className='chart-container-left-talent' >
                        <div id="talent-flow-chart-container"></div>
                        <div>
                            <div style={{display: 'flex'}}>

                                <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                                    <ul style={{ listStyleType: 'none', textAlign: 'left'}}>
                                        <li>Total Inflow: {putCommasInNumber(totalInflow)}</li>
                                        <li>Private: {putCommasInNumber(privateInflow)}</li>
                                        {showGenderProb && <li>Pct Female Inflow: {(totalFemaleInflowProb * 100).toFixed(1)} %</li>}
                                    </ul>
                                </div>
                                <div style={{display: 'flex', flex: '1', alignItems: 'stretch', flexDirection: 'column'}}>
                                    <ul style={{ listStyleType: 'none', textAlign: 'right', marginRight: '30px'}}>
                                        <li>Total Outflow: {putCommasInNumber(totalOutflow)}</li>
                                        <li>Pending: {putCommasInNumber(pendingOutflow)}</li>
                                        {showGenderProb && <li>Pct Female Outflow: {(totalFemaleOutflowProb * 100).toFixed(1)} %</li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    <DownloadButton 
                        data = {talentFlowData} 
                        regions = {selectedRegions}
                        departments = {selectedDepartments}
                        seniorities = {selectedSeniorities}
                        startMonth = {getMonthName(startMonth)}
                        startYear = {startYear}
                        endMonth = {getMonthName(endMonth)}
                        endYear = {endYear}
                        companyName={companyName} 
                        buttonText = {'Download Talent Flow Data'}
                        fileName = { `${companyName}_talent_flow_data.xlsx` }
                        dataType={'talentFlow'}
                        accessToken={accessToken}
                    />
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>Data as of {getLastDateOfMonth(dataVersionDateStore.dataVersionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                        <li>All data and analysis is based on publicly available profiles</li>
                        <li>Top 25 companies listed on inflow and outflow</li>
                        <li>'Private' inflow means that the employee started at the target company, but the employee's previous experience is not known</li>
                        <li>'Pending' outflow means that the employee has ended his/her experience at the target company, but has not yet specified a subsequent experience</li>
                    </ul>
                </div>
                </div> )}
            </div>
        );
    }
});

export default CompanyTalentFlowComponent;