import { useEffect, useMemo, useState, useRef } from "react";
import { healthCheckReportDataStore } from "../../../stores/health_check_report_data_store";
import LoadingWithText from "../../common/loading_text";
import { toJS } from "mobx";
import Highcharts from 'highcharts';
import Select from 'react-select';


const IndustryDistributionChart = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const [binSize, setBinSize] = useState({ value: 1, label: '1%' });
    const [threshold, setThreshold] = useState(20);

    const chartRefs = useRef([null, null, null]);
    const [chartData, setChartData] = useState([]);

    const binSizeOptions = [
        { value: 1, label: '1%' },
        { value: 2, label: '2%' },
        { value: 5, label: '5%' },
        { value: 10, label: '10%' },
    ];

    const employeeCountMapping = {
        '5': '<10 employees',
        '10': '10 to 15 employees',
        '15': '15 to 20 employees',
        '20': '20 to 30 employees',
        '30': '30 to 50 employees',
        '50': '50 to 100 employees',
        '100': '100 to 200 employees',
        '200': '200 to 400 employees',
        '400': '400 to 600 employees',
        '600': '600 to 1000 employees',
        '1000': '1000+ employees'
    }
     


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await healthCheckReportDataStore.fetchIndustryDistribtuionForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const industryDistData = healthCheckReportDataStore.industryDistributionData[targetCompanyId];

            if (industryDistData !== null) {
                setData(toJS(industryDistData));
                console.log('industryDistData', toJS(industryDistData));
                setDataLoading(false);
            }
            else {
                console.log('industryDistData is null');
                setDataLoading(false);
            }
        };
        try {
            getData();
        }
        catch (error) {
            console.log('Error fetching industry distribution data', error);
        }

    }, [targetCompanyId, companyList, endDate, dataset]);


    // useEffect(() => {
    //     if (data!== null) {
    //       updateCharts();
    //     }
    // }, [data, binSize, threshold]);

    useEffect(() => {
        try{
            if (data!== null) {
                const newChartData = [0, 5].map(year => processDataAndCreateOptions(year));
                setChartData(newChartData);
            }
        }
        catch (error) {
            console.log('Error processing data and creating options', error);
        }
    }, [data, binSize, threshold]);

    
    const processDataAndCreateOptions = (yearsAgo) => {
        const endDateYear = new Date(endDate).getFullYear();
        const targetYear = endDateYear - yearsAgo;
    
        const filteredData = data.filter(item => new Date(item.the_date).getFullYear() === targetYear);
    
        const bins = {};
        for (let i = 0; i < threshold; i += binSize.value) {
            bins[i] = 0;
        }
        bins[threshold] = 0;

        let companyPctDigital = null;
        let companyPctDigitalIndexedScore = null;
        let industry = null;
        let companySize = null;
    
        filteredData.forEach(item => {
            const pctDigital = item.pct_digital_bucket * 100;
            if (pctDigital >= threshold) {
                bins[threshold] += item.num_companies;
            } else {
                const binKey = Math.floor(pctDigital / binSize.value) * binSize.value;
                bins[binKey] += item.num_companies;
            }

            // Store the company_pct_digital_bucket value
            if (companyPctDigital === null) {
                companyPctDigital = item.company_pct_digital * 100;
                companyPctDigitalIndexedScore = item.company_pct_digital_indexed_score * 100;
            }

            if (industry === null) {
                industry = item.company_industry_g1;
                companySize = item.est_employees_count_raw_bucket;
            }
        });
    
        const processedData = Object.entries(bins).map(([key, value]) => [
            key === threshold.toString() ? `${key}+%` : `${key}%`,
            value
        ]);

        // Find the appropriate bin for the company_pct_digital_bucket
        const companyBin = Math.min(Math.floor(companyPctDigital / binSize.value) * binSize.value, threshold);
        const companyBinIndex = processedData.findIndex(item => parseInt(item[0]) === companyBin);


        const yearDiff = endDateYear - targetYear;
        const chartTitle = yearDiff === 0 ? 'Industry Distribution of Pct Digital (Current)' : `Industry Distribution of Pct Digital (${yearDiff} yrs ago)`;
        return {
            chartOptions: {
                chart: { type: 'column', height: '50%'},
                title: { text: chartTitle, align: 'left', style: { fontSize: '18px' } },
                xAxis: { 
                    title: { 
                        text: 'Pct Digital Bins',
                        style: {
                            fontSize: '20px'
                        }
                    },
                    categories: processedData.map(item => item[0]),
                    tickInterval: 1,
                    labels: {
                        // formatter: function() {
                        //     // Only show labels for ticks that are multiples of the bin size
                        //     const value = parseInt(this.value);
                        //     if (value % binSize.value === 0 || value === threshold) {
                        //         return this.value;
                        //     }
                        //     return '';
                        // },
                        style: {
                            fontSize: '12px'
                        },
                        rotation: -90,
                        y: 5,     
                        step: 1,               
                    },
                    min: 1,
                },
                yAxis: { 
                    title: { 
                        text: '# of Companies',
                        style: {
                            fontSize: '20px'
                        }
                    },
                    labels: {
                        style: {
                            fontSize: '20px'
                        }
                    }
                },
                legend: {   
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px',
                    },
                },
                series: [
                    { 
                        name: null, 
                        data: processedData.map(item => item[1]),
                        showInLegend: false,
                    },
                    {
                        name: `${targetCompanyName}`,
                        type: 'scatter',
                        // data: [[companyBinIndex, processedData[companyBinIndex][1] * 1.05]],
                        data: [{
                            x: companyBinIndex,
                            y: processedData[companyBinIndex][1],
                            companyPctDigital: companyPctDigital
                        }],
                        marker: {
                            symbol: 'diamond', // or 'circle' if you prefer
                            radius: 8,
                            fillColor: 'red',
                            lineColor: 'red',
                            lineWidth: 2
                        },
                        tooltip: {
                            // pointFormat: 'Target Pct Digital: {point.x}'
                            pointFormat: 'Pct Digital: {point.companyPctDigital:.0f}%'
                        },

                    }
                ],
                credits: { enabled: false }
            },
            companyInfo: {
                year: targetYear,
                pctDigital: companyPctDigital ? companyPctDigital.toFixed(1) : 'N/A',
                percentile: companyPctDigitalIndexedScore ? companyPctDigitalIndexedScore.toFixed(0) : 'N/A',
                industry: industry,
                companySize: companySize,
            }
        };
    };


    const updateCharts = () => {
        chartData.forEach((chartInfo, index) => {
            if (chartRefs.current[index]) {
            chartRefs.current[index].update(chartInfo.chartOptions);
            } else {
            chartRefs.current[index] = Highcharts.chart(`chart-container-${index}`, chartInfo.chartOptions);
            }
        });
    };

    useEffect(() => {
        try {
            if (chartData.length > 0) {
                updateCharts();
            }
        }
        catch (error) {
            console.log('Error updating charts', error);
        }
    }, [chartData]);


    

    return (
        <div>
            
            {
                dataLoading
                ? 
                (
                    <div>
                        <LoadingWithText texts={["Requesting Industry Distribution Data...", "Finished"]} interval={5000}/>
                    </div>
                )
                :
                (
                    <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                            <label style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>
                                Bin Size:
                            </label>
                            <Select
                                value={binSize}
                                onChange={setBinSize}
                                options={binSizeOptions}
                                styles={{
                                    container: (provided) => ({
                                    ...provided,
                                    width: '100px',
                                    marginLeft: '10px',
                                    })
                                }}
                            />
                        
                        </div>
                        <span style={{ margin: '20px' }}></span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ marginRight: '5px' }}>
                                Max Threshold:
                            </label>
                            <input 
                                type="number" 
                                value={threshold} 
                                onChange={(e) => setThreshold(Number(e.target.value))}
                                step="5"
                                style={{ marginLeft: '10px', width: '40px', padding: '10px', borderRadius: '5px'}}
                                max="80"
                            />
                            <span style={{ 
                                    padding: '10px', 
                                    // backgroundColor: '#f0f0f0', 
                                    borderRadius: '0 5px 5px 0',
                                    // border: '1px solid #ccc',
                                    borderLeft: 'none'
                                }}
                            >
                                %
                            </span>
                        </div>
                    </div>
                    <br/>
                    <div id="industry-distribution-charts">
                        {chartData.map((chartInfo, index) => (
                        <div key={index}>
                        {/* <div key={index} style={{ marginLeft: '5%'}}> */}
                        {/* <div key={index} style={{marginLeft: '5%'}} className='chart-container-left-health-check'> */}
                            <div id={`chart-container-${index}`} />
                            <p style={{ textAlign: 'center', marginTop: '10px', fontSize: '16px' }}>
                            In {chartInfo.companyInfo.year}, {targetCompanyName}'s digital footprint was {chartInfo.companyInfo.pctDigital}% putting it in the {chartInfo.companyInfo.percentile}th percentile among its peers.
                            </p>
                            <p style={{ textAlign: 'center', marginTop: '10px', fontSize: '16px' }}>
                            Benchmark Definition: Companies with {employeeCountMapping[chartInfo.companyInfo.companySize]} employees in the {chartInfo.companyInfo.industry} industry.
                            </p>
                            
                            {
                                index === 0 && (
                                    <>
                                        <hr className="dotted-line" />
                                        <div style={{ marginBottom: '20px' }} />
                                    </>
                                )
                            }
                        </div>
                        ))}
                    </div>
                    </>
                )
            }
        </div>
    );
}

export default IndustryDistributionChart;