import React, { useEffect, useMemo, useState } from 'react';
import { toJS } from "mobx";
import { digitalBenchmarkReportDataStore } from "../../../stores/digital_benchmark_report_data_store";
import LoadingWithText from "../../common/loading_text";
import { digitalTranformationTypeMappingReverse } from "../utils";

const languageMapping = {
  main_flag_java: { name: "Java", order: 1 },
  main_flag_c: { name: "C", order: 2 },
  main_flag_cpp: { name: "C++", order: 3 },
  main_flag_csharp: { name: "C#", order: 4 },
  main_flag_dotnet: { name: ".Net", order: 5 },
  main_flag_asp_net: { name: "ASP.net", order: 6 },
  main_flag_cobol: { name: "Cobol", order: 7 },
  main_flag_fortran: { name: "Fortran", order: 8 },
  main_flag_pascal: { name: "Pascal", order: 9 },
  main_flag_assembly: { name: "Assembly", order: 10 },
  main_flag_visualbasic: { name: "Visual Basic", order: 11 },
  main_flag_unix_linux: { name: "Unix/Linux", order: 12 },
  main_flag_ruby: { name: "Ruby", order: 13 },
  main_flag_python: { name: "Python", order: 14 },
  main_flag_javascript: { name: "Javascript", order: 15 },
  main_flag_jquery: { name: "JQuery", order: 16 },
  main_flag_sql: { name: "SQL", order: 17 },
  main_flag_react: { name: "React", order: 18 },
  main_flag_react_native: { name: "React Native", order: 19 },
  main_flag_typescript: { name: "TypeScript", order: 20 },
  main_flag_uml: { name: "UML", order: 21 },
  main_flag_sas: { name: "SAS", order: 22 }
};


const CodingLangsTable = ({ requestData, digitalType = null }) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const title = digitalType === null
        ? `Programming Languages by Company`
        : `Programming Languages by Digital Function: ${digitalTranformationTypeMappingReverse[digitalType]}`;
    const divId = digitalType === null
        ? 'coding-langs-table'
        : `coding-langs-table-${digitalType}`;

    const getHeatmapColor = (value) => {
        // Convert percentage string to number
        const numValue = parseFloat(value);
        
        if (isNaN(numValue)) return 'white';
        
        // Normalize the value to a range of -1 to 1
        const normalizedValue = Math.max(-1, Math.min(1, numValue / 20));

        let r, g, b;
        
        if (normalizedValue < 0) {
            // Red to White
            r = 255;
            g = b = Math.round(255 * (1 + normalizedValue));
            
        } else {
            // White to Green
            g = 255;
            r = b = Math.round(255 * (1 - normalizedValue));
            
        }

        return `rgb(${r}, ${g}, ${b})`;
    };

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            let codingLangsData = [];

            if (digitalType !== null) {
                await digitalBenchmarkReportDataStore.fetchCodingLangsByDigitalTypeForAllCompanies(
                    targetCompanyId,
                    companyList,
                    endDate,
                    dataset
                );
                codingLangsData = digitalBenchmarkReportDataStore.codingLangsByDigitalTypeData[targetCompanyId];
            } else {
                await digitalBenchmarkReportDataStore.fetchCodingLangsForAllCompanies(
                    targetCompanyId,
                    companyList,
                    endDate,
                    dataset
                );
                codingLangsData = digitalBenchmarkReportDataStore.codingLangsData[targetCompanyId];
            }

            if (codingLangsData !== null) {
                setData(toJS(codingLangsData));
                console.log('codingLangsData', toJS(codingLangsData));
            }
            else {
                console.log('codingLangsData is null');
            }
            setDataLoading(false);
        };

        getData();
    }, [targetCompanyId, companyList, endDate, dataset, digitalType]);

    const splitCompanies = (companies) => {
        const chunks = [];
        // Pull first company out of the list
        const targetCompany = companies.shift();
        let remainingCompanies = [...companies];

        // If there are no remaining companies, return a chunk with just the target company
        if (remainingCompanies.length === 0) {
            chunks.push([targetCompany]);
            return chunks;
        }

        // Split remaining companies into chunks of 5
        while (remainingCompanies.length > 0) {
            const chunkSize = Math.min(5, remainingCompanies.length);
            // Create slice of remaining companies
            const newChunk = [targetCompany, ...remainingCompanies.slice(0, chunkSize)];
            // Add slice to chunks
            chunks.push(newChunk);
            remainingCompanies = remainingCompanies.slice(chunkSize);
        }

        return chunks;
    };

    const processData = (data) => {
        if (!data) return { programmingLanguages: [], companies: [], countData: {}, percentageData: {} };

        const companies = [];
        const countData = {};
        const percentageData = {};

        const filteredData = digitalType !== null ? data.filter(company => company.digital_transformation_type === digitalType) : data;

        filteredData.forEach(company => {
            companies.push(company.official_name);

            Object.keys(company).forEach(key => {
                if (key.startsWith('main_flag_')) {
                    const langInfo = languageMapping[key];
                    if (langInfo) {
                        if (!countData[key]) countData[key] = {};
                        countData[key][company.official_name] = company[key];
                    }
                } else if (key.startsWith('pct_main_flag_')) {
                    const flagKey = key.replace('pct_', '');
                    const langInfo = languageMapping[flagKey];
                    if (langInfo) {
                        if (!percentageData[flagKey]) percentageData[flagKey] = {};
                        percentageData[flagKey][company.official_name] = company[key];
                    }
                }
            });
        });

        const programmingLanguages = Object.keys(languageMapping)
            .sort((a, b) => languageMapping[a].order - languageMapping[b].order);

        return { programmingLanguages, companies, countData, percentageData };
    };

    const renderTable = (programmingLanguages, companies, tableData, headerText, isPercentage = false, chunkIndex) => {
        const getFormattedValue = (value) => isPercentage
            ? value ? `${(value * 100).toFixed(0)}%` : '0%'
            : value || 0;

        return (
            <div key={chunkIndex}
                 id={`inner-${divId}-${chunkIndex}`}
                 style={{ marginBottom: '20px',
                          width: "100%"}}>
                <table
                    className="company-table"
                    style={{
                        width: `${(companies.length * 15) + 10}%`,
                        tableLayout: 'fixed',
                    }}
                >
                    <thead>
                        <tr>
                            <th style={{ width: '10%' }}>{headerText}</th>
                            {companies.map(company => (
                                <th key={company} style={{ width: '15%', whiteSpace: 'normal', wordWrap: 'break-word', overflow: 'hidden' }}>{company}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {programmingLanguages.map(lang => (
                            <tr key={lang}>
                                <td style={{ width: '10%' }}>{languageMapping[lang].name}</td>
                                {companies.map(company => {
                                    const value = tableData[lang]?.[company];
                                    const formattedValue = getFormattedValue(value);
                                    const cellStyle = {
                                        //border: '1px solid black',
                                        padding: '5px',
                                        ...(isPercentage && value !== undefined ? { backgroundColor: getHeatmapColor(formattedValue) } : {})
                                    };
                                    return (
                                        <td key={company} style={cellStyle}>
                                            {formattedValue}
                                        </td>
                                    );
                                    })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const tableContent = useMemo(() => {
        if (!data) return null;

        const { programmingLanguages, companies, countData, percentageData } = processData(data);
        const companyChunks = splitCompanies([...companies]);

        return (
            <>
                {companyChunks.map((chunk, index) =>
                    renderTable(programmingLanguages, chunk, countData, 'Num Members', false, index)
                )}
                {companyChunks.map((chunk, index) =>
                    renderTable(programmingLanguages, chunk, percentageData, 'Pct of Digital', true, index)
                )}
            </>
        );
    }, [data]);

    return (
        <div>
            <h2 style={{ textAlign: 'left', marginLeft: '5%' }}>{title}</h2>
            {dataLoading ? (
                <div>
                    <LoadingWithText texts={['Requesting coding langs data...', 'Finished']} interval={5000} />
                </div>
            ) : (
                <div id={divId}>
                    <div style={{ marginLeft: '5%', overflowX: 'auto' }}>
                        {tableContent}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CodingLangsTable;
