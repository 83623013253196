import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { talentMgmtReportDataStore } from "../../../stores/talent_mgmt_report_data_store";
import LoadingWithText from "../../common/loading_text";
import Highcharts from 'highcharts';
import { putCommasInNumber } from "../../../utils/utils";
import { talentTypeMappingReverse } from "../utils";


const TalentSankeyChart = ({requestData, talentType=null}) => {
    const { targetCompanyId, targetCompanyName, duration, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const [showTop25, setShowTop25] = useState(false);

    const [totalInflow, setTotalInflow] = useState(0);
    const [totalOutflow, setTotalOutflow] = useState(0);
    const [privateInflow, setPrivateInflow] = useState(0);
    const [pendingOutflow, setPendingOutflow] = useState(0);
    const [totalFemaleInflowProb, setTotalFemaleInflowProb] = useState(0);
    const [totalFemaleOutflowProb, setTotalFemaleOutflowProb] = useState(0);

    const title = talentType === null ? `${targetCompanyName}: Talent Strategy Talent Inflow/Outflow in the Last 5 Years` : `${targetCompanyName}: ${talentTypeMappingReverse[talentType]} Talent Inflow/Outflow in the Last 5 Years`;
    const divId = talentType === null ? 'digital-sankey-chart' : `digital-sankey-chart-${talentType}`;
    const chartContainerId = talentType === null ? 'talent-flow-chart-container' : `talent-flow-chart-container-${talentType}`;


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await talentMgmtReportDataStore.fetchTalentFlowData(targetCompanyId, duration, dataset, talentType);
            const talentFlowData = talentMgmtReportDataStore.talentFlowData[targetCompanyId];

            if (talentFlowData !== null) {
                setData(toJS(talentFlowData));
                console.log('talentFlowData', toJS(talentFlowData));
                setDataLoading(false);
            }
            else {
                console.log('talentFlowData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, duration, dataset]);

    useEffect(() => {
        if (data !== null) {

            const drawSankeyChart = (talentFlowData) => {
                if (!talentFlowData || talentFlowData.length === 0) {
                    console.log("No talent flow data found");
                    return null;
                }
        
                let filteredSankeyInData = talentFlowData.filter((item) => item['dataset'] === 'inflow');
                let filteredSankeyOutData = talentFlowData.filter((item) => item['dataset'] === 'outflow');
                
                let companyEmpInStatsMap = {};
                let totalEmpInCount = 0;
                let privateInflowCount = 0;
                let totalSumFirstNameFemaleProbIn = 0;
                let totalNumGenderProbIn = 0;
        
                for (let i = 0; i < filteredSankeyInData.length; i++) {
                    if (filteredSankeyInData[i]['from_company_name'] === 'Private') {
                        privateInflowCount += filteredSankeyInData[i]['employee_count'];
                    }
                    totalEmpInCount += filteredSankeyInData[i]['employee_count'];
                    totalSumFirstNameFemaleProbIn += filteredSankeyInData[i]['sum_first_name_female_prob'];
                    totalNumGenderProbIn += filteredSankeyInData[i]['num_gender_prob'];
        
                    // skip if from_company_name is Other
                    if (filteredSankeyInData[i]['from_company_name'] === 'Other') {
                        continue;
                    }
                    
                    if (filteredSankeyInData[i]['from_company_id'] in companyEmpInStatsMap) {
                        companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['empCount'] += filteredSankeyInData[i]['employee_count'];
                        companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['totalSumFirstNameFemaleProb'] += filteredSankeyInData[i]['sum_first_name_female_prob'];
                        companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['totalNumGenderProb'] += filteredSankeyInData[i]['num_gender_prob']
                    } else {
                        companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']] = {
                            pastCompanyName: filteredSankeyInData[i]['from_company_name'],
                            empCount: filteredSankeyInData[i]['employee_count'],
                            totalSumFirstNameFemaleProb: filteredSankeyInData[i]['sum_first_name_female_prob'],
                            totalNumGenderProb: filteredSankeyInData[i]['num_gender_prob']
                        };
                    };
                };
                
                const companyEmpInStatsMapSortedByCount = Object.entries(companyEmpInStatsMap).sort((a, b) => b[1]['empCount'] - a[1]['empCount']);
                
                let companyEmpOutStatsMap = {};
                let totalEmpOutCount = 0;
                let pendingOutflowCount = 0;
                let totalSumFirstNameFemaleProbOut = 0;
                let totalNumGenderProbOut = 0;
        
                for (let i = 0; i < filteredSankeyOutData.length; i++) {
                    if (filteredSankeyOutData[i]['to_company_name'] === 'Pending') {
                        pendingOutflowCount += filteredSankeyOutData[i]['employee_count'];
                    }
                    totalEmpOutCount += filteredSankeyOutData[i]['employee_count'];
                    totalSumFirstNameFemaleProbOut += filteredSankeyOutData[i]['sum_first_name_female_prob'];
                    totalNumGenderProbOut += filteredSankeyOutData[i]['num_gender_prob'];
        
                    // skip if to_company_name is Other
                    if (filteredSankeyOutData[i]['to_company_name'] === 'Other') {
                        continue;
                    }
                    
                    if (filteredSankeyOutData[i]['to_company_id'] in companyEmpOutStatsMap) {
                        companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['empCount'] += filteredSankeyOutData[i]['employee_count'];
                        companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['totalSumFirstNameFemaleProb'] += filteredSankeyOutData[i]['sum_first_name_female_prob'];
                        companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['totalNumGenderProb'] += filteredSankeyOutData[i]['num_gender_prob']
                    } else {
                        companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']] = {
                            currentCompanyName: filteredSankeyOutData[i]['to_company_name'], 
                            empCount: filteredSankeyOutData[i]['employee_count'],
                            totalSumFirstNameFemaleProb: filteredSankeyOutData[i]['sum_first_name_female_prob'],
                            totalNumGenderProb: filteredSankeyOutData[i]['num_gender_prob']
                        };
                    };
                };
                
        
                const companyEmpOutStatsMapSortedByCount = Object.entries(companyEmpOutStatsMap).sort((a, b) => b[1]['empCount'] - a[1]['empCount']);
        
                setTotalInflow(totalEmpInCount);
                setTotalOutflow(totalEmpOutCount);
                setPrivateInflow(privateInflowCount);
                setPendingOutflow(pendingOutflowCount);
                setTotalFemaleInflowProb(totalSumFirstNameFemaleProbIn / totalNumGenderProbIn);
                setTotalFemaleOutflowProb(totalSumFirstNameFemaleProbOut / totalNumGenderProbOut);
               
                const sankeyInSeriesData = [];
                const sankeyOutSeriesData = [];
        
                let count = 0;
                let showGenderProb = false;
                for (let i = 0; i < companyEmpInStatsMapSortedByCount.length; i++) {
                    if (companyEmpInStatsMapSortedByCount[i][1]['pastCompanyName'] !== 'Private') {
                        let genderProbText = '';
                        if (showGenderProb && companyEmpInStatsMapSortedByCount[i][1]['totalNumGenderProb'] >= 10) {
                            genderProbText = ' [' + (companyEmpInStatsMapSortedByCount[i][1]['totalSumFirstNameFemaleProb'] / companyEmpInStatsMapSortedByCount[i][1]['totalNumGenderProb'] * 100).toFixed(0) + '%F]';
                        }
                        sankeyInSeriesData.push({
                            id: count,
                            from: companyEmpInStatsMapSortedByCount[i][1]['empCount'] + ':' + companyEmpInStatsMapSortedByCount[i][1]['pastCompanyName'] + genderProbText,
                            to: targetCompanyName,
                            weight: companyEmpInStatsMapSortedByCount[i][1]['empCount'],
                            color: '#999999',
                        });
                    };
                    if (count === (showTop25 ? 25 : 10)){
                        break;
                    }
                    count += 1;
                };
                
                for (let i = 0; i < companyEmpOutStatsMapSortedByCount.length; i++) {
                    if (companyEmpOutStatsMapSortedByCount[i][1]['currentCompanyName'] !== 'Pending') {
                        let genderProbText = '';
                        if (showGenderProb && companyEmpOutStatsMapSortedByCount[i][1]['totalNumGenderProb'] >= 10) {
                            genderProbText = '[' + (companyEmpOutStatsMapSortedByCount[i][1]['totalSumFirstNameFemaleProb'] / companyEmpOutStatsMapSortedByCount[i][1]['totalNumGenderProb'] * 100).toFixed(0) + '%F] ';
                        }
                        sankeyOutSeriesData.push({
                            id: count,
                            from: targetCompanyName,
                            to: genderProbText + companyEmpOutStatsMapSortedByCount[i][1]['currentCompanyName'] + ':' + companyEmpOutStatsMapSortedByCount[i][1]['empCount'],
                            weight: companyEmpOutStatsMapSortedByCount[i][1]['empCount'],
                            color: '#999999',
                        });
                    };
                    if (count === (showTop25 ? 50 : 20)) {
                        break;
                    }
                    count += 1;
                };
        
                const sankeyChartSeriesData = sankeyInSeriesData.concat(sankeyOutSeriesData);
                
                Highcharts.chart(chartContainerId, 
                {
                    chart: {
                        //height: '55%',
                        // width: 1280,
                        height: showTop25 ? 820: 720,
                    },
        
                    title: {
                        text: null,
                    },
        
                    subtitle: {
                        text: null,
                    },
                    
                    legend: {
                        enabled: true,
                    },
                    
                    series: [{
                        keys: ['from', 'to', 'weight'],
                        data: sankeyChartSeriesData,
                        type: 'sankey',
                        name: 'Talent Flow',
                        // links: links,
                        linkOpacity: 0.5,
                        // nodes: nodes,
                        tooltip: {
                            nodeFormatter: function () {
                                return this.name.split(':')[1];
                            },
                            pointFormatter: function () {
                                if(this.toNode.name === targetCompanyName) {
                                    // inflow hover
                                    let fromName = this.fromNode.name.split(':')[1];
                                    let fromNameWithoutGenderProb = fromName.replace(/\s\[\d+%F\]/, '');
                                    return fromNameWithoutGenderProb + ' -> ' + this.toNode.name + ': ' + this.weight;
                                } else {
                                    // outflow hover
                                    let toName = this.toNode.name.split(':')[0];
                                    let toNameWithoutGenderProb = toName.replace(/\[\d+%F]\s/, '');
                                    return this.fromNode.name + ' -> ' + toNameWithoutGenderProb + ': ' + this.weight;
                                }
                                //return this.fromNode.name + ' -> ' + this.toNode.name + ': ' + this.weight;
                            }
                        },
                    }],
        
                    plotOptions: {
                        sankey: {
                            nodeWidth: 5,
                            // nodePadding: showTop25 ? 20: 10,
                            dataLabels: {
                                enabled: true,
                                allowOverlap: true,
                                color: 'black',
                                style: {
                                    textOutline: 'none',
                                    fontSize: showTop25 ? '11px' : '16px',
                                },
                            },
                            label: {
                                style: {
                                    fontSize: showTop25 ? '11px' : '16px',
                                },
                            },
                        },
                    },
        
                    credits: {
                        enabled: false
                    },
        
                });
            };

            drawSankeyChart(data);
        }
    }, [data, showTop25]);

    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {title} </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting talent data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                <>
                     <div style={{marginLeft: '5%', marginBottom: '10px'}}>
                        <input 
                            type="checkbox" 
                            id="showTop25" 
                            checked={showTop25} 
                            onChange={(e) => setShowTop25(e.target.checked)}
                        />
                        <label htmlFor="showTop25">Show Top 25</label>
                    </div>
                    <div id={divId}>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={chartContainerId} />
                            <div class="container">
                                <div>
                                    <div class="section-title">Total Inflow: {putCommasInNumber(totalInflow)}</div>
                                    <div class="section-title">Private: {putCommasInNumber(privateInflow)}</div>
                                </div>
                                <div>
                                    <div class="section-title">Total Outflow: {putCommasInNumber(totalOutflow)}</div>
                                    <div class="section-title">Pending: {putCommasInNumber(pendingOutflow)}</div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </>
                )
            }
        </div>
    );
};

export default TalentSankeyChart;
