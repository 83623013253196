// TableOfContents.js
import React from 'react';
import { Link } from 'react-scroll';

const TableOfContents = () => {
  const sections = [
    { id: 'percentageDigitalTable', name: 'Percentage Talent Table' },
    { id: 'industryDistribution', name: 'Industry Distribution' },
    { id: 'employeePercentage', name: 'Employee Percentage' },
    { id: 'employeeHiringAttrition', name: 'Employee Hiring and Attrition' },
    { id: 'digitalMakeupByFunction', name: 'Talent Makeup by Function' },
    { id: 'digitalMakeupByFunctionTargetPeer', name: 'Talent Makeup by Function - Target vs Peer' },
    { id: 'percentageDigitalTargetPeer', name: 'Percentage Talent Roles - Target vs Peer' },
    { id: 'digitalGrowthRatesByFunction', name: 'Talent Growth Rates by Function' },
    { id: 'digitalHiringTrend', name: 'Talent Hiring Trend' },
    { id: 'digitalAttritionTrend', name: 'Talent Attrition Trend' },
    { id: 'digitalNetHCTargetPeer', name: 'Talent Net HC - Target vs Peer' },

    { id: 'digitalHiringTargetPeer', name: 'Talent Hiring - Target vs Peer' },
    { id: 'digitalAttritionTargetPeer', name: 'Talent Attrition - Target vs Peer' },
    { id: 'digitalSankey', name: 'Talent Talent Inflow/Outflow' },
    { id: 'digitalCohortRetentionTargetPeer', name: 'Talent Avg Cohort Retention - Target vs Peer' },
    { id: 'avgCohortRetention', name: 'Talent Avg Cohort Retention' },
    { id: 'avgCohortRetentionByFunction', name: 'Talent Avg Cohort Retention by Function' },
    // { id: 'cSuiteHires', name: 'C-suite Hires' },
    // { id: 'cSuiteExits', name: 'C-suite Exits' },
    { id: 'geoMap', name: 'Geo Map' },
    { id: 'geoTableHC', name: 'Geo Table - HC' },
    { id: 'geoTablePct', name: 'Geo Table - Pct' },
    { id: 'geoPeerTable', name: 'Geo Peer Table' },
    { id: 'companyCareerTenure', name: 'Company Career Tenure' },
    { id: 'detailedAnalysis', name: 'Detailed Analysis' },
    { id: 'appendix', name: 'Appendix' },
    { id: 'downloadReport', name: 'Download Report'},
    // Add more sections as needed
  ];

  return (
    <div className="table-of-contents">
      <h3>Table of Contents</h3>
      <ul>
        {sections.map((section) => (
          <li key={section.id}>
            <Link
              to={section.id}
              smooth={true}
              duration={500}
              spy={true}
              offset={-100} // Adjust this value based on your layout
              activeClass='active'
            >
              {section.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableOfContents;
