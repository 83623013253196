import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";


class DigitalBenchmarkReportDataStore {
    loading = false;
    percentageDigitalData = null;
    industryDistributionData = null;
    headcountHistoryData = null;
    headcountHistoryByDigitalTypeData = null;
    headcountHistoryByFxData = null;
    retentionHistoryData = null;
    talentFlowData = null;
    tenureHistoryData = null;
    tenureHistoryByDigitalTypeData = null;
    cSuiteHiresData = null;
    cSuiteExitsData = null;
    topTitlesData = null;
    codingLangsData = null;
    codingLangsByDigitalTypeData = null;
    geoData = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchPercentageDigitalData(targetCompanyId, companyList, endDate, dataset) {
        if (this.percentageDigitalData !== null && this.percentageDigitalData[targetCompanyId] !== undefined) {
            return;
        }

        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let percentageDigitalUrl = apiUrl + process.env.REACT_APP_API_DBM_PERCENTAGE_DIGITAL_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching percentage digital data from ${percentageDigitalUrl}...`);

            const response = await axios.post(percentageDigitalUrl, data, config);
            if (response.status === 200) {
                let percentageDigitalData = JSON.parse(response.data.data.percentage_digital);
                console.log(`DigitalBenchmarkReportDataStore: Fetched percentage digital data from ${percentageDigitalUrl}, ${percentageDigitalData.length} records`);
                if (this.percentageDigitalData === null) {
                    this.percentageDigitalData = {};
                }
                this.percentageDigitalData[targetCompanyId] = percentageDigitalData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_percentage_digital',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_PERCENTAGE_DIGITAL_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchIndustryDistribtuionForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.industryDistributionData !== null && this.industryDistributionData[targetCompanyId] !== undefined) {
            return;
        }
        
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let industryDistributionUrl = apiUrl + process.env.REACT_APP_API_DBM_INDUSTRY_DISTRIBUTION_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching industry distribution data from ${industryDistributionUrl}...`);

            const response = await axios.post(industryDistributionUrl, data, config);
            if (response.status === 200) {
                let industryDistributionData = JSON.parse(response.data.data.industry_distribution);
                console.log(`DigitalBenchmarkReportDataStore: Fetched industry distribution data from ${industryDistributionUrl}, ${industryDistributionData.length} records`);
                if (this.industryDistributionData === null) {
                    this.industryDistributionData = {};
                }
                this.industryDistributionData[targetCompanyId] = industryDistributionData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_industry_distribution',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_INDUSTRY_DISTRIBUTION_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
            
    };

    async fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryData !== null && this.headcountHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryUrl = apiUrl + process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching headcount history data from ${headcountHistoryUrl}...`);

            const response = await axios.post(headcountHistoryUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryData = JSON.parse(response.data.data.headcount_history);
                console.log(`DigitalBenchmarkReportDataStore: Fetched headcount history data from ${headcountHistoryUrl}, ${headcountHistoryData.length} records`);
                if (this.headcountHistoryData === null) {
                    this.headcountHistoryData = {};
                }
                
                this.headcountHistoryData[targetCompanyId] = headcountHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_headcount_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchHeadcountHistoryByDigitalTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryByDigitalTypeData !== null && this.headcountHistoryByDigitalTypeData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryByDigitalTypeUrl = apiUrl + process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_BY_DIGITAL_TYPE_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching headcount history by digital type data from ${headcountHistoryByDigitalTypeUrl}...`);

            const response = await axios.post(headcountHistoryByDigitalTypeUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryByDigitalTypeData = JSON.parse(response.data.data.headcount_history);
                console.log(`DigitalBenchmarkReportDataStore: Fetched headcount history by digital type data from ${headcountHistoryByDigitalTypeUrl}, ${headcountHistoryByDigitalTypeData.length} records`);
                if (this.headcountHistoryByDigitalTypeData === null) {
                    this.headcountHistoryByDigitalTypeData = {};
                }
                
                this.headcountHistoryByDigitalTypeData[targetCompanyId] = headcountHistoryByDigitalTypeData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_headcount_history_by_digital_type',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_BY_DIGITAL_TYPE_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryByFxData !== null && this.headcountHistoryByFxData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryByFxUrl = apiUrl + process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_BY_FX_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching headcount history by fx data from ${headcountHistoryByFxUrl}...`);

            const response = await axios.post(headcountHistoryByFxUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryByFxData = JSON.parse(response.data.data.headcount_history_by_fx);
                console.log(`DigitalBenchmarkReportDataStore: Fetched headcount history by fx data from ${headcountHistoryByFxUrl}, ${headcountHistoryByFxData.length} records`);
                if (this.headcountHistoryByFxData === null) {
                    this.headcountHistoryByFxData = {};
                }
                
                this.headcountHistoryByFxData[targetCompanyId] = headcountHistoryByFxData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_headcount_history_by_fx',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchRetentionHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset, digitalType) {
        if (digitalType === null && this.retentionHistoryData !== null && this.retentionHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let retentionHistoryUrl = apiUrl + process.env.REACT_APP_API_DBM_RETENTION_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
                "filter": digitalType,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching retention history data from ${retentionHistoryUrl}...`);

            const response = await axios.post(retentionHistoryUrl, data, config);
            if (response.status === 200) {
                let retentionHistoryData = JSON.parse(response.data.data.retention_history);
                console.log(`DigitalBenchmarkReportDataStore: Fetched retention history data from ${retentionHistoryUrl}, ${retentionHistoryData.length} records`);
                if (this.retentionHistoryData === null) {
                    this.retentionHistoryData = {};
                }
                
                this.retentionHistoryData[targetCompanyId] = retentionHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_retention_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_RETENTION_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "filter": digitalType,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }


    };

    async fetchTalentFlowData(targetCompanyId, duration, dataset, digitalType) {
        if (digitalType === null && this.talentFlowData !== null && this.talentFlowData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let talentFlowUrl = apiUrl + process.env.REACT_APP_API_DBM_TALENT_FLOW_HISTORY_ENDPOINT;

            let data = {
                "company_id": targetCompanyId,
                "duration": duration,
                "dataset": dataset,
                "filter": digitalType,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching talent flow history data from ${talentFlowUrl}...`);

            const response = await axios.post(talentFlowUrl, data, config);
            if (response.status === 200) {
                let talentflowHistoryData = JSON.parse(response.data.data.talent_flow);
                console.log(`DigitalBenchmarkReportDataStore: Fetched talent flow history data from ${talentFlowUrl}, ${talentflowHistoryData.length} records`);
                if (this.talentFlowData === null) {
                    this.talentFlowData = {};
                }
                
                this.talentFlowData[targetCompanyId] = talentflowHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_talent_flow_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_TALENT_FLOW_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "duration": duration,
                        "target_company_id": targetCompanyId,
                        "filter": digitalType,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }


    };

    async fetchTenureHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.tenureHistoryData !== null && this.tenureHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let tenureHistoryUrl = apiUrl + process.env.REACT_APP_API_DBM_TENURE_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching tenure history data from ${tenureHistoryUrl}...`);

            const response = await axios.post(tenureHistoryUrl, data, config);
            if (response.status === 200) {
                let tenureHistoryData = JSON.parse(response.data.data.tenure_history);
                console.log(`DigitalBenchmarkReportDataStore: Fetched tenure history data from ${tenureHistoryUrl}, ${tenureHistoryData.length} records`);
                if (this.tenureHistoryData === null) {
                    this.tenureHistoryData = {};
                }
                
                this.tenureHistoryData[targetCompanyId] = tenureHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_tennure_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_TENURE_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchTenureHistoryByDigitalTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.tenureHistoryByDigitalTypeData !== null && this.tenureHistoryByDigitalTypeData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let tenureHistoryByDigitalTypeUrl = apiUrl + process.env.REACT_APP_API_DBM_TENURE_HISTORY_BY_DIGITAL_TYPE_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching tenure history by digital type data from ${tenureHistoryByDigitalTypeUrl}...`);

            const response = await axios.post(tenureHistoryByDigitalTypeUrl, data, config);
            if (response.status === 200) {
                let tenureHistoryByDigitalTypeData = JSON.parse(response.data.data.tenure_history);
                console.log(`DigitalBenchmarkReportDataStore: Fetched tenure history by digital type data from ${tenureHistoryByDigitalTypeUrl}, ${tenureHistoryByDigitalTypeData.length} records`);
                if (this.tenureHistoryByDigitalTypeData === null) {
                    this.tenureHistoryByDigitalTypeData = {};
                }
                
                this.tenureHistoryByDigitalTypeData[targetCompanyId] = tenureHistoryByDigitalTypeData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_tennure_history_by_digital_type',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_TENURE_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchCSuiteHiresForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.cSuiteHiresData !== null && this.cSuiteHiresData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let cSuiteHiresUrl = apiUrl + process.env.REACT_APP_API_DBM_CSUITE_HIRES_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching csuite hires data from ${cSuiteHiresUrl}...`);

            const response = await axios.post(cSuiteHiresUrl, data, config);
            if (response.status === 200) {
                let cSuiteHiresData = JSON.parse(response.data.data.c_suite_hires);
                console.log(`DigitalBenchmarkReportDataStore: Fetched csuite hires data from ${cSuiteHiresUrl}, ${cSuiteHiresData.length} records`);
                if (this.cSuiteHiresData === null) {
                    this.cSuiteHiresData = {};
                }
                
                this.cSuiteHiresData[targetCompanyId] = cSuiteHiresData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_csuite_hires_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_CSUITES_HIRES_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchCSuiteExitsForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.cSuiteExitsData !== null && this.cSuiteExitsData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let cSuiteExitsUrl = apiUrl + process.env.REACT_APP_API_DBM_CSUITE_EXITS_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching csuite exits data from ${cSuiteExitsUrl}...`);

            const response = await axios.post(cSuiteExitsUrl, data, config);
            if (response.status === 200) {
                let cSuiteExitsData = JSON.parse(response.data.data.c_suite_exits);
                console.log(`DigitalBenchmarkReportDataStore: Fetched csuite exits data from ${cSuiteExitsUrl}, ${cSuiteExitsData.length} records`);
                if (this.cSuiteExitsData === null) {
                    this.cSuiteExitsData = {};
                }
                
                this.cSuiteExitsData[targetCompanyId] = cSuiteExitsData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_csuite_exits_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_CSUITES_EXITS_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchTopTitlesForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.topTitlesData !== null && this.topTitlesData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let topTitlesUrl = apiUrl + process.env.REACT_APP_API_DBM_TOP_TITLES_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching top titles data from ${topTitlesUrl}...`);

            const response = await axios.post(topTitlesUrl, data, config);
            if (response.status === 200) {
                let topTitlesData = JSON.parse(response.data.data.top_titles);
                console.log(`DigitalBenchmarkReportDataStore: Fetched top titles data from ${topTitlesUrl}, ${topTitlesData.length} records`);
                if (this.topTitlesData === null) {
                    this.topTitlesData = {};
                }
                
                this.topTitlesData[targetCompanyId] = topTitlesData;
            }

            this.loading = false;

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_top_titles_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_TOP_TITLES_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };
    
    async fetchCodingLangsForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.codingLangsData !== null && this.codingLangsData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let codingLangsUrl = apiUrl + process.env.REACT_APP_API_DBM_CODING_LANGS_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching coding langs data from ${codingLangsUrl}...`);

            const response = await axios.post(codingLangsUrl, data, config);
            if (response.status === 200) {
                let codingLangsData = JSON.parse(response.data.data.coding_langs);
                console.log(`DigitalBenchmarkReportDataStore: Fetched coding langs data from ${codingLangsUrl}, ${codingLangsData.length} records`);
                if (this.codingLangsData === null) {
                    this.codingLangsData = {};
                }
                
                this.codingLangsData[targetCompanyId] = codingLangsData;
            }

            this.loading = false;

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_coding_langs',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_CODING_LANGS_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchCodingLangsByDigitalTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.codingLangsByDigitalTypeData !== null && this.codingLangsByDigitalTypeData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let codingLangsByDigitalTypeUrl = apiUrl + process.env.REACT_APP_API_DBM_CODING_LANGS_BY_DIGITAL_TYPE_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching coding langs by digital type data from ${codingLangsByDigitalTypeUrl}...`);

            const response = await axios.post(codingLangsByDigitalTypeUrl, data, config);
            if (response.status === 200) {
                let codingLangsData = JSON.parse(response.data.data.coding_langs);
                console.log(`DigitalBenchmarkReportDataStore: Fetched coding langs by digital type data from ${codingLangsByDigitalTypeUrl}, ${codingLangsData.length} records`);
                if (this.codingLangsByDigitalTypeData === null) {
                    this.codingLangsByDigitalTypeData = {};
                }
                
                this.codingLangsByDigitalTypeData[targetCompanyId] = codingLangsData;
            }

            this.loading = false;

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_coding_langs_by_digital_type',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_CODING_LANGS_BY_DIGITAL_TYPE_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchGeoForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.geoData !== null && this.geoData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let geoUrl = apiUrl + process.env.REACT_APP_API_DBM_GEO_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`DigitalBenchmarkReportDataStore: Fetching geo data from ${geoUrl}...`);

            const response = await axios.post(geoUrl, data, config);
            if (response.status === 200) {
                let geoData = JSON.parse(response.data.data.geo_data);
                console.log(`DigitalBenchmarkReportDataStore: Fetched geo data from ${geoUrl}, ${geoData.length} records`);
                if (this.geoData === null) {
                    this.geoData = {};
                }
                
                this.geoData[targetCompanyId] = geoData;
            }

            this.loading = false;

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_geo',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_GEO_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };
}

export const digitalBenchmarkReportDataStore = new DigitalBenchmarkReportDataStore();
