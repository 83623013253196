const MonthYearPicker = ({ value, onChange }) => {
    // Parse current value or default to "1900-01"
    const [year, month] = value?.split('-') || ['1900', '01'];

    // Get current year for max limit
    const currentYear = new Date().getFullYear();
    const years = Array.from(
        { length: currentYear - 1900 + 1 }, 
        (_, i) => 1900 + i
    );

    const months = [
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];

    const handleChange = (type, newValue) => {
        const newDate = type === 'year' 
        ? `${newValue}-${month}`
        : `${year}-${newValue}`;
        onChange(newDate);
    };

    return (
        <div style={{ display: 'inline-flex', gap: '8px' }}>
        <select 
            value={month}
            onChange={(e) => handleChange('month', e.target.value)}
            style={{ padding: '4px' }}
        >
            {months.map(({ value, label }) => (
            <option key={value} value={value}>{label}</option>
            ))}
        </select>
        <select 
            value={year}
            onChange={(e) => handleChange('year', e.target.value)}
            style={{ padding: '4px' }}
        >
            {years.map(year => (
            <option key={year} value={year}>{year}</option>
            ))}
        </select>
        </div>
    );
};

export default MonthYearPicker;
