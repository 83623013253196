import { useEffect, useState } from "react";
import Highcharts from 'highcharts';

import { useLocation, useSearchParams, NavLink, useNavigate } from "react-router-dom";
import { customSort, getLastDateOfMonth, getGoogleAnalyticsId, getNumberOfDaysBetweenDates } from "../../utils/utils";
import { DownloadButton } from "../common/download_button";
import { lineColors, lineStyles } from '../../utils/constants';
import Select from 'react-select';

import { observer } from "mobx-react";
import { tenureDataStore } from "../../stores/tenure_data_store";
import { dataVersionDateStore } from "../../stores/data_version_date_store";
import { metadataStore } from "../../stores/metadata_store";
import LoadingWithText from "../common/loading_text";
import { toJS } from "mobx";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import { collectionsStore } from '../../stores/collections_store';
import Dropdown from '../common/collections_dropdown';
import { mixpanelActions } from "../../utils/mixpanel_util";
import { amplitudeActions } from "../../utils/amplitude_util";


const CompanyTenureComponent = observer(() => {
    const [tenureData, setTenureData] = useState(null);
    const [universeAvgData, setUniverseAvgData] = useState(null);

    const [showUniverseAvgs, setShowUniverseAvgs] = useState(false);

    const geo1Values = ['North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific'];
    const deptValues = ['Sales', 'Product', 'Administrative', 'Consulting', 'Customer Success & Support', 'Engineering', 'Finance', 'Human Resources', 'Information Technology', 'Marketing', 'Operations', 'Other' ];
    const seniorityValues = ["Individual Contributor", "Advisor / Board", "Executive", "Leadership", "Management", "Student / Intern", "Unsure"];

    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [selectedSeniorities, setSelectedSeniorities] = useState([]);
    const [collections, setCollections] = useState([]);

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [publicProfileCount, setPublicProfileCount] = useState(0);
    const [companyName, setCompanyName] = useState('');
    const [dataVersionDate, setDataVersionDate] = useState(null);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    const dataset = searchParams && searchParams.get('dataset') || location.state && location.state.dataset;

    // let dataVersionDate = location.state && location.state.dataVersionDate;
    let companyGrowthPath = `/company/growth?company_id=${companyId}`;
    let teamGrowthPath = `/company/team/growth?company_id=${companyId}`; 
    let talentFlowPath = `/company/talent?company_id=${companyId}`;
    let talentRetentionPath = `/company/talent/retention?company_id=${companyId}`
    let talentRetentionPlusPath = `/company/talent/retention/plus?company_id=${companyId}`
    let tenurePath = `/company/tenure?company_id=${companyId}`;
    let topTitlesPath = `/company/top-titles?company_id=${companyId}`;
    let geoPath = `/company/geo?company_id=${companyId}`;
    
    if (accessToken !== null && accessToken !== undefined) {
        companyGrowthPath += `&access_token=${accessToken}`;
        teamGrowthPath += `&access_token=${accessToken}`;
        talentFlowPath += `&access_token=${accessToken}`;
        talentRetentionPath += `&access_token=${accessToken}`;
        talentRetentionPlusPath += `&access_token=${accessToken}`;
        tenurePath += `&access_token=${accessToken}`;
        topTitlesPath += `&access_token=${accessToken}`;
        geoPath += `&access_token=${accessToken}`;
    }
    
    if (dataset !== null && dataset !== undefined) {
        companyGrowthPath += `&dataset=${dataset}`;
        teamGrowthPath += `&dataset=${dataset}`;
        talentFlowPath += `&dataset=${dataset}`;
        talentRetentionPath += `&dataset=${dataset}`;
        talentRetentionPlusPath += `&dataset=${dataset}`;
        tenurePath += `&dataset=${dataset}`;
        topTitlesPath += `&dataset=${dataset}`;
        geoPath += `&dataset=${dataset}`;
    }

    let universeSummaryPath = '';

    if (universeId !== null && universeId !== undefined) {
        companyGrowthPath += `&universe_id=${universeId}`;
        teamGrowthPath += `&universe_id=${universeId}`;
        talentFlowPath += `&universe_id=${universeId}`;
        talentRetentionPath += `&universe_id=${universeId}`;
        talentRetentionPlusPath += `&universe_id=${universeId}`;
        tenurePath += `&universe_id=${universeId}`;
        topTitlesPath += `&universe_id=${universeId}`;
        geoPath += `&universe_id=${universeId}`;
        universeSummaryPath = `/universe/summary?universe_id=${universeId}`;
    }

    const handleDeptChangeWithMultiSelect = (selectedOptions) => {
        
        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedDepartments(selectedOptions);
        } else {
            setSelectedDepartments([selectedOptions]);
        }

         // update div style to be visible
         let refreshDataDiv = document.getElementById('refresh-data-text');
         refreshDataDiv.style.display = 'block';
    };

    const handleSeniorityChangeWithMultiSelect = (selectedOptions) => {
       
        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedSeniorities(selectedOptions);
        } else {
            setSelectedSeniorities([selectedOptions]);
        }

         // update div style to be visible
         let refreshDataDiv = document.getElementById('refresh-data-text');
         refreshDataDiv.style.display = 'block';
    };

    const handleGeo1ChangeWithMultiSelect = (selectedOptions) => {

        // check if selectedOptions is of type Array
        if (Array.isArray(selectedOptions)) {
            setSelectedRegions(selectedOptions);
        } else {
            setSelectedRegions([selectedOptions]);
        }

         // update div style to be visible
         let refreshDataDiv = document.getElementById('refresh-data-text');
         refreshDataDiv.style.display = 'block';
    };

    const handleRefreshClicked = () => {
        setIsPageLoading(true);
        getTenureData();
    };

    const displayTalentRetention = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com" 
                || emailDomain === "endava.com" 
                || emailDomain === "bcg.com"
                || emailDomain === "telemetryllc.com" 
                || emailDomain === "telemetry-llc.com" 
                || emailDomain === "american-securities.com") 
            {
                return true;
            }
            else if (emailDomain === "gmail.com") {
                if (userAccountDataStore.user.email === 'zoltan.peresztegi@gmail.com') {
                    return true;
                }
            }
        }
        return false;
    };

    const displayTalentRetentionPlus = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com" ) 
            {
                return true;
            }
        }
        return false;
    };

    const getMetaData = async() => {
        let promiseArray = [
            metadataStore.fetchMetadata(companyId, accessToken, dataset),
            dataVersionDateStore.fetchDataVersionDate(accessToken),
        ];

        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            promiseArray.push(collectionsStore.fetchCollections(userAccountDataStore.user.email, companyId));
        }

        // await all promises in parallel
        await Promise.all(promiseArray);

        setCompanyName(metadataStore.data[companyId].companyName);
        if (metadataStore.data[companyId].publicProfileCount !== undefined) {
            setPublicProfileCount(metadataStore.data[companyId].publicProfileCount);
        }
        setDataVersionDate(toJS(dataVersionDateStore.dataVersionDate));

        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            setCollections(toJS(collectionsStore.collections[companyId]));
        }
        
        setIsMetaDataLoading(false);
    };

    const getTenureData = async () => {
        try {
            const dataVersionDateFormat = new Date(dataVersionDate);
            const endDate = `${dataVersionDateFormat.getFullYear()}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;
            const startDate = `${dataVersionDateFormat.getFullYear() - 10}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;

            const geo1 = selectedRegions.map((item) => item.value);
            const dept = selectedDepartments.map((item) => item.value);
            const seniority = selectedSeniorities.map((item) => item.value);

            await tenureDataStore.fetchTenureData(companyId, universeId, accessToken, startDate, endDate, geo1, dept, seniority, dataset);
            
            setTenureData(toJS(tenureDataStore.data[companyId]));

            setIsPageLoading(false);
        } catch (error) {
            console.log(error);
            setIsPageLoading(false);
        }
    };

    function getTenureChartOptions() {
        const displayUniverseAvgs = universeAvgData !== null && showUniverseAvgs;
        
        let filteredData = tenureData;

		const avgCompanyTenureYearsData = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_company_tenure_years.toFixed(1))];
		});
		const avgCareerTenureYearsData = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_career_tenure_years.toFixed(1))];
		});
        const avgCompanyTenureYearsDataFemale = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_company_tenure_years_f.toFixed(1))];
		});
		const avgCareerTenureYearsDataFemale = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_career_tenure_years_f.toFixed(1))];
		});
        const avgCompanyTenureYearsDataMale = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_company_tenure_years_m.toFixed(1))];
		});
		const avgCareerTenureYearsDataMale = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_career_tenure_years_m.toFixed(1))];
		});
		
        const dataSeries = [
            {
                type: 'spline',
                name: 'Avg Career Tenure',
                data: avgCareerTenureYearsData,
                color: lineColors['Career Tenure'],
            },
            {
                type: 'spline',
                name: 'Avg Career Tenure - Female',
                data: avgCareerTenureYearsDataFemale,
                color: lineColors['Career Tenure'],
                dashStyle: lineStyles['Career Tenure Female'],
                visible: false,
            },
            {
                type: 'spline',
                name: 'Avg Career Tenure - Male',
                data: avgCareerTenureYearsDataMale,
                color: lineColors['Career Tenure'],
                dashStyle: lineStyles['Career Tenure Male'],
                visible: false,
            },
            {
                type: 'spline',
                name: 'Avg Company Tenure',
                data: avgCompanyTenureYearsData,
                color: lineColors['Company Tenure'],
            },
            {
                type: 'spline',
                name: 'Avg Company Tenure - Female',
                data: avgCompanyTenureYearsDataFemale,
                color: lineColors['Company Tenure'],
                dashStyle: lineStyles['Company Tenure Female'],
                visible: false,
            },
            {
                type: 'spline',
                name: 'Avg Company Tenure - Male',
                data: avgCompanyTenureYearsDataMale,
                color: lineColors['Company Tenure'],
                dashStyle: lineStyles['Company Tenure Male'],
                visible: false,
            },
            
        ];

        // if (displayUniverseAvgs) {
        //     const filteredUniverseAvgData = universeAvgData.filter((item) => {
        //         if (selectedRegion.value === 'All') {
        //             return item.geo1 === "Global" && item.derived_department_group === selectedDepartment.value && item.derived_seniority_group === selectedSeniority.value;
        //         }

        //         return item.geo1 === selectedRegion.value && item.derived_department_group === selectedDepartment.value && item.derived_seniority_group === selectedSeniority.value;
        //     });

        //     const avgUniverseCompanyTenureYearsData = filteredUniverseAvgData.map((item) => {
        //         return [item.the_date, parseFloat(item.avg_avg_company_tenure_years.toFixed(1))];
        //     });
        //     const avgUniverseCareerTenureYearsData = filteredUniverseAvgData.map((item) => {
        //         return [item.the_date, parseFloat(item.avg_avg_career_tenure_years.toFixed(1))];
        //     });
        //     const avgUniverseCompanyTenureYearsDataFemale = filteredUniverseAvgData.map((item) => {
        //         return [item.the_date, parseFloat(item.avg_avg_company_tenure_years_f.toFixed(1))];
        //     });
        //     const avgUniverseCareerTenureYearsDataFemale = filteredUniverseAvgData.map((item) => {
        //         return [item.the_date, parseFloat(item.avg_avg_career_tenure_years_f.toFixed(1))];
        //     });
        //     const avgUniverseCompanyTenureYearsDataMale = filteredUniverseAvgData.map((item) => {
        //         return [item.the_date, parseFloat(item.avg_avg_company_tenure_years_m.toFixed(1))];
        //     });
        //     const avgUniverseCareerTenureYearsDataMale = filteredUniverseAvgData.map((item) => {
        //         return [item.the_date, parseFloat(item.avg_avg_career_tenure_years_m.toFixed(1))];
        //     });

        //     dataSeries.push(
        //         {
        //             type: 'spline',
        //             name: 'Avg Career Tenure - Peer Avg',
        //             data: avgUniverseCareerTenureYearsData,
        //             color: lineColors['Career Tenure'],
        //             dashStyle: 'DotDotLongDash',
        //         },
        //         {
        //             type: 'spline',
        //             name: 'Avg Career Tenure - Female - Peer Avg',
        //             data: avgUniverseCareerTenureYearsDataFemale,
        //             color: lineColors['Career Tenure'],
        //             dashStyle: 'DotDotShortDash',
        //             visible: false,
        //         },
        //         {
        //             type: 'spline',
        //             name: 'Avg Career Tenure - Male - Peer Avg',
        //             data: avgUniverseCareerTenureYearsDataMale,
        //             color: lineColors['Career Tenure'],
        //             dashStyle: 'ShortDashDotDot',
        //             visible: false,
        //         },
        //         {
        //             type: 'spline',
        //             name: 'Avg Company Tenure - Peer Avg',
        //             data: avgUniverseCompanyTenureYearsData,
        //             color: lineColors['Company Tenure'],
        //             dashStyle: 'DotDotLongDash',
        //         },
        //         {
        //             type: 'spline',
        //             name: 'Avg Company Tenure - Female - Peer Avg',
        //             data: avgUniverseCompanyTenureYearsDataFemale,
        //             color: lineColors['Company Tenure'],
        //             dashStyle: 'DotDotShortDash',
        //             visible: false,
        //         },
        //         {
        //             type: 'spline',
        //             name: 'Avg Company Tenure - Male - Peer Avg',
        //             data: avgUniverseCompanyTenureYearsDataMale,
        //             color: lineColors['Company Tenure'],
        //             dashStyle: 'ShortDashDotDot',
        //             visible: false,
        //         },
        //     );

        // }

		return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '25%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Tenure',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: 'Years'
				},
				labels: {
					format: '{value}'
				}
			},
			legend: {
				enabled: true,
			},
            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} years</b><br/>',
            },
			
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                // setTenureDistributionDate(new Date(dateStr));
                            }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
		
	};

    function getCompanyTenureChartOptions() {
        if (tenureData === null || tenureData === undefined || tenureData.length === 0) { 
            return {};
        }

        const categoriesMap = {
            '0-1':  [], 
            '1-3':  [], 
            '3-5':  [], 
            '5-10': [], 
            '10-20':  [],
            '20+':  [],
        };
        const dataSeries = [];
        let filteredData = tenureData;

        for (let i = 0; i < filteredData.length; i++) {
            let item = filteredData[i];

            let data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_0_1) * 100).toFixed(1))];
            categoriesMap['0-1'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_1_3) * 100).toFixed(1))];
            categoriesMap['1-3'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_3_5) * 100).toFixed(1))];
            categoriesMap['3-5'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_5_10 * 100)).toFixed(1))];
            categoriesMap['5-10'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_10_20) * 100).toFixed(1))];
            categoriesMap['10-20'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_20_plus) * 100).toFixed(1))];
            categoriesMap['20+'].push(data);
        }

        for (let key in categoriesMap) {
            dataSeries.push({
                name: key === '0-1' ? key + 'year' : key + ' years',
                data: categoriesMap[key],
                color: lineColors['Company Tenure ' + key],
                type: 'column',
                stacked: true,
                stacking: 'percent',
                pointWidth: 13.5,
            });
        }

        return {
			chart: {
				zoomType: 'xy',
                type: 'column',
                height: '25%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            title: {
				text: 'Company Tenure Distribution',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: 'Distribution',
				},
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    },
                },
			},
			tooltip: {
                pointFormatter: function() {
                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                },
                shared: true,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};

    };

    function getCareerTenureChartOptions() {
        if (tenureData === null || tenureData === undefined || tenureData.length === 0) { 
            return {};
        }

        const categoriesMap = {
            '0-1':  [], 
            '1-3':  [], 
            '3-5':  [], 
            '5-10': [], 
            '10-20':  [],
            '20+':  [],
        };
        const dataSeries = [];
        let filteredData = tenureData;

        for (let i = 0; i < filteredData.length; i++) {
            let item = filteredData[i];

            let data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_0_1) * 100).toFixed(1))];
            categoriesMap['0-1'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_1_3) * 100).toFixed(1))];
            categoriesMap['1-3'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_3_5) * 100).toFixed(1))];
            categoriesMap['3-5'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_5_10 * 100)).toFixed(1))];
            categoriesMap['5-10'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_10_20) * 100).toFixed(1))];
            categoriesMap['10-20'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_20_plus) * 100).toFixed(1))];
            categoriesMap['20+'].push(data);
        }

        for (let key in categoriesMap) {
            dataSeries.push({
                name: key === '0-1' ? key + 'year' : key + ' years',
                data: categoriesMap[key],
                color: lineColors['Career Tenure ' + key],
                type: 'column',
                stacked: true,
                stacking: 'percent',
                pointWidth: 13,
            });
        }

        return {
			chart: {
				zoomType: 'xy',
                type: 'column',
                height: '25%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            title: {
				text: 'Career Tenure Distribution',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: 'Distribution',
				},
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    },
                },
			},
			tooltip: {
                pointFormatter: function() {
                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                },
                shared: true,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};

    };

    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
        if (userAccountDataStore.user !== null || accessToken !== null || accessToken !== undefined) {
            setIsMetaDataLoading(true);
            getMetaData();
        }
    }, []);

    useEffect(() => {
        if (publicProfileCount !== 0) {
            setIsPageLoading(true);
            getTenureData();
        }
        
    }, [publicProfileCount]);


    useEffect(() => {
        try{
            if (publicProfileCount != 0 
                && document.getElementById('tenure-chart-container') !== null  
                && document.getElementById('company-tenure-dist-chart-container') !== null
                && document.getElementById('career-tenure-dist-chart-container') !== null
                && tenureData !== null
            ) {
                const tenureChartOptions = getTenureChartOptions();
                Highcharts.chart('tenure-chart-container', tenureChartOptions);
                
                const companyTenureChartOptions = getCompanyTenureChartOptions();
                Highcharts.chart('company-tenure-dist-chart-container', companyTenureChartOptions);

                const careerTenureChartOptions = getCareerTenureChartOptions();
                Highcharts.chart('career-tenure-dist-chart-container', careerTenureChartOptions);
            };
        }
        catch(error) {
            console.log(error);
        }

    }, [tenureData]);

    useEffect(() => {
        try {
            if (publicProfileCount != 0 
                && document.getElementById('tenure-chart-container') !== null  
                && document.getElementById('company-tenure-dist-chart-container') !== null
                && document.getElementById('career-tenure-dist-chart-container') !== null
                && tenureData !== null
            ) {
                const tenureChartOptions = getTenureChartOptions();
                Highcharts.chart('tenure-chart-container', tenureChartOptions);
            }
        }
        catch(error) {
            console.log(error);
        }
    }, [showUniverseAvgs]);

    const customStyles = {
        control: (provided) => ({
        ...provided,
        width: 200,
        }),
        menu: (provided) => ({
        ...provided,
        width: 200,
        }),
    };
 
    function handleTabClick(path) {
        let type = '';
        if (path.includes("team")) {
            type = "Team Growth";
        }
        else if (path.includes("company")) {
            type = "Company Growth";
        }
        else if (path.includes("talent")) {
            if (path.includes("retention")) {
                type = "Talent Retention";
            }
            else {
                type = "Talent Flow";
            }
        }


        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Tenure",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Tenure",
                type: type,
                user: toJS(userAccountDataStore.user),
            });
        }
        navigate(path);
    };

    if (metadataStore.isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if (publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...', 'Setting up app...']} interval={2000} />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        let loadingTexts = ['Requesting data...', 'Loading into charts...', 'Adding final touches...'];
        if (universeId !== null && universeId !== undefined) {
            loadingTexts.push('Adding universe data...');
        }
        loadingTexts.push('Finishing charts...');
        loadingTexts.push('Adding final touches...');
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={companyGrowthPath}>Company</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={teamGrowthPath}>Teams</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={talentFlowPath}>Talent Flow</a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        {
                            displayTalentRetention() && (
                                <div className="button">
                                    <a href={talentRetentionPath}>Talent Retention</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetention() && (
                                <span style={{ margin: '5px'}}/>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <div className="button">
                                    <a href={talentRetentionPlusPath}>Retention +</a>
                                </div>
                            )
                        }
                        {
                            displayTalentRetentionPlus() && (
                                <span style={{ margin: '5px'}} />
                            )
                        }
                        <div className="button active">
                            Tenure
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={topTitlesPath}> Top Titles </a>
                        </div>
                        <span style={{ margin: '5px'}}/>
                        <div className="button">
                            <a href={geoPath}>Geo</a>
                        </div> 
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px', marginRight: '50px'}}>
                        {/* { universeId !== null && (
                            <NavLink 
                                style={{border: 'black 2px solid'}}
                                className='button'
                                to={universeSummaryPath}
                                state={{universeId: universeId, accessToken: accessToken}}
                                target="_blank" rel="noopener noreferrer"
                            >
                                View Universe
                            </NavLink>
                        )} */}
                        { userAccountDataStore.user !== null && <Dropdown collections={collections}/> }
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Employee Tenure: {companyName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <LoadingWithText texts={loadingTexts} interval={2000} />
                ) : (
                <div>
                <div className='checkbox-group-container'> 
                    <div className='checkbox-container'>
                        <label htmlFor='regions'>Region:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="regions"
                            isMulti={true}
                            options={geo1Values.map((value) => ({value: value, label: value}))}
                            value={selectedRegions}
                            onChange={handleGeo1ChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    <div className='checkbox-container'>
                        <label htmlFor='departments'>Team:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="departments"
                            isMulti={true}
                            options={deptValues.map((value) => ({value: value, label: value}))}
                            value={selectedDepartments}
                            onChange={handleDeptChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    <div className='checkbox-container'>
                        <label htmlFor='seniorities'>Seniority:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="seniorities"
                            isMulti={true}
                            options={seniorityValues.map((value) => ({value: value, label: value}))}
                            value={selectedSeniorities}
                            onChange={handleSeniorityChangeWithMultiSelect}
                            styles={customStyles}
                        />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className='button' onClick={handleRefreshClicked}>Refresh Data</button>
                </div>
                <br/>
                <div id="refresh-data-text" style={{ alignContent: 'cemter', justifyContent: 'center', textAlign: 'center', display: 'none'}}>
                    Please hit Refresh Data to apply the new filters
                </div>
                <br/>
                {/* <div style={{textAlign: 'center'}}>
                    { (universeId !== null && tenureDataStore.universeData && tenureDataStore.universeData[universeId]) 
                        ? (
                            <label>
                                <input type="checkbox" checked={showUniverseAvgs} onChange={() => setShowUniverseAvgs(!showUniverseAvgs)} />
                                &nbsp;Compare with Universe Average
                            </label>
                        )
                        : ( universeId !== null && 
                            <div>
                                <LoadingWithText texts={['Adding universe data to compare...']} interval={3000} />
                            </div>
                        )
                    }
                </div> */}
                <br/>
                <div className='chart-container'>
                    <div className='chart-container-left-hc-1' id="tenure-chart-container" />
                </div>
                <br/>
                <div className='chart-container'>
                    <div className='chart-container-left-hc-1' id="company-tenure-dist-chart-container" />
                </div>
                <br/>
                <div className='chart-container'>
                    <div className='chart-container-left-hc-1' id="career-tenure-dist-chart-container" />
                </div>
                
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    <DownloadButton 
                        data = {tenureData}
                        regions = {selectedRegions}
                        departments = {selectedDepartments}
                        seniorities = {selectedSeniorities}
                        companyName={companyName} 
                        buttonText = {'Download Tenure Data'}
                        fileName = { `${companyName}_tenure_data.xlsx` }
                        dataType={'tenure'}
                        accessToken={accessToken}
                    />
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>Data as of {getLastDateOfMonth(dataVersionDateStore.dataVersionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                        <li>All data and analysis is based on publicly available profiles</li>
                    </ul>
                </div>
                </div> )}
            </div>
        );
    }
});

export default CompanyTenureComponent;
