import React from "react";
import { putCommasInNumber } from "../../utils/utils";

export const CompanySearchResultsTable = (props) => {
    let divStyle = {
        width: '100%', 
        overflowY: 'auto', 
        border: '1px solid black',
        display: 'block'
    }
    if (!props.onlySearch) {
        divStyle['height'] = '300px';
    }

    const handleGrowthClick = (e, company_id) => {
        e.preventDefault();
        let url = `/company/growth?company_id=${company_id}`;
        if (props.accessToken) {
            url += '&access_token=' + props.accessToken;
        }
        
        window.open(url, '_blank');
    };

    const handleTeamsClick = (e, company_id) => {
        e.preventDefault();
        let url = `/company/team/growth?company_id=${company_id}`;
        if (props.accessToken) {
            url += '&access_token=' + props.accessToken;
        }
        
        window.open(url, '_blank');
    };

    const handleTalentClick = (e, company_id) => {
        e.preventDefault();
        let url = `/company/talent?company_id=${company_id}`;
        if (props.accessToken) {
            url += '&access_token=' + props.accessToken;
        }

        window.open(url, '_blank');
    };

    const handleTenureClick = (e, company_id) => {
        e.preventDefault();
        let url = `/company/tenure?company_id=${company_id}`;
        if (props.accessToken) {
            url += '&access_token=' + props.accessToken;
        }

        window.open(url, '_blank');
    };

    const handleTopTitlesClick = (e, company_id) => {
        e.preventDefault();
        let url = `/company/top-titles?company_id=${company_id}`;
        if (props.accessToken) {
            url += '&access_token=' + props.accessToken;
        }

        window.open(url, '_blank');
    };

    const handleGeoClick = (e, company_id) => {
        e.preventDefault();
        let url = `/company/geo?company_id=${company_id}`;
        if (props.accessToken) {
            url += '&access_token=' + props.accessToken;
        }

        window.open(url, '_blank');
    };
    

    return (
        <div style={divStyle}>
        <table className="home-table">
            <thead>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            checked={props.selectAll}
                            onChange={() => {props.handleSelectAll()}}
                        />
                    </th>
                    <th>Company ID</th>
                    {/* <th>Company Shorthand Name</th> */}
                    <th>Company Name</th>
                    <th>Insights</th>
                    <th>LinkedIn</th>
                    <th>Website</th>
                    <th>Signal Score</th>
                    <th>Employee Count</th>
                    <th>Public Profiles</th>
                </tr>
            </thead>
            <tbody>
                {props.searchDataItems.map((item, index) => (
                    <tr key={item.index}>
                        <td>
                            <input
                                type="checkbox"
                                checked={props.selectedRows[index] || false}
                                onChange={() => {props.handleSelect(index, item)} }
                            />
                        </td>
                        <td>{item.company_id}</td>
                        {/* <td>{item.shorthand_name}</td> */}
                        <td>
                            {item.name ? item.name : 'N/A'}
                        </td>
                        <td>
                            <>
                            <button 
                                // style={{ color: 'teal'}}
                                className='button'
                                type="button" 
                                onClick={(e) => handleGrowthClick(e, item.company_id)}
                            > Company 
                            </button>
                            <span style={{ margin: '2px' }} />
                            <button
                                // style={{ color: 'teal'}}
                                className='button'
                                type="button" 
                                onClick={(e) => handleTeamsClick(e, item.company_id)}
                            > Teams 
                            </button>
                            <span style={{ margin: '2px' }} />
                            <button 
                                // style={{ color: 'teal'}}
                                className='button'
                                type="button" 
                                onClick={(e) => handleTalentClick(e, item.company_id)}
                            > Talent </button>
                            <span style={{ margin: '2px' }} />
                            <button 
                                // style={{ color: 'teal'}}
                                className='button'
                                type="button" 
                                onClick={(e) => handleTenureClick(e, item.company_id)}
                            > Tenure </button>
                            <span style={{ margin: '2px' }} />
                            <button 
                                // style={{ color: 'teal'}}
                                className='button'
                                type="button" 
                                onClick={(e) => handleTopTitlesClick(e, item.company_id)}
                            > Top Titles </button>
                            <span style={{ margin: '2px' }} />
                            <button 
                                // style={{ color: 'teal'}}
                                className='button'
                                type="button" 
                                onClick={(e) => handleGeoClick(e, item.company_id)}
                            > Geo </button>
                            </>
                        </td>
                        <td>
                            <a href={item.linkedin_url} target="_blank" rel="noopener noreferrer">
                                {item.linkedin_url}
                            </a>
                        </td>
                        <td>
                            <a href={item.website} target="_blank" rel="noopener noreferrer">
                                {item.website}
                            </a>
                        </td>
                        <td>{item.signal_score ? parseFloat(item.signal_score * 100).toFixed(1)+'%' : 'N/A'}</td>
                        <td>{item.employees_count ? putCommasInNumber(item.employees_count) : 'N/A'}</td>
                        <td>{item.num_valid_public_profiles ? putCommasInNumber(item.num_valid_public_profiles) : 'N/A'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );
};
